import React, {useEffect, useState} from 'react';
import LargeCardsContainer from "../LargeCardsContainer";
import SmallCardsContainer from "../SmallCardsContainer";
import NoData from "../../../../common/NoData";
import {container} from "tsyringe";
import ReportingHttpService from "../../../../../utils/services/reportingHttpService";
import {IReportingHttpService} from "../../../../../utils/services/interfaces/IReportingHttp.service";
import {IDealSummaryResponse} from "../../../../../typings/api-models/deal.models";
import toast from "react-hot-toast";
import LoadingIndicator from "../../../../common/LoadingIndicator";
import {NoRowsOverlayPropsOverrides} from "@mui/x-data-grid";
import {useTranslation} from "react-i18next";
import NetworkErrorMessages from "../../../../../utils/networkErrorMessages";

const Overview = ({startDate, endDate, tabChange, unmounting, setUnmounting}: {
    startDate?: Date,
    endDate?: Date,
    tabChange: (tab: number) => void,
    unmounting: any,
    setUnmounting: any
}) => {
    const [t, i18n] = useTranslation('locale');
    const reportingHttpService: IReportingHttpService = container.resolve(ReportingHttpService);
    const initDealSummaryResponse: IDealSummaryResponse = {
        deal_count: 0,
        impressions: 0,
        revenue: 0,
        ecpm: 0,
        total_bids: 0,
        winning_bids: 0,
        total_auctions: 0,
        plays: 0,
        played_impressions: 0,
        played_revenue: 0
    };
    const noData: NoRowsOverlayPropsOverrides = {
        showIcon: "filter_list_off",
        title: t('creatives.data-grid.no-deal-title'),
        description: t('creatives.data-grid.no-deal-description')
    };

    const [isLoading, setIsLoading] = useState(false);
    const [dealsSummary, setDealsSummary] = useState<IDealSummaryResponse>(initDealSummaryResponse);
    const [currentNoDataProps, setCurrentNoDataProps] = useState(noData);
    const useNetworkErrorMessage = NetworkErrorMessages();

    useEffect(() => {
        const fetchOverviewData = async () => {
            try {
                if (startDate != undefined && endDate != undefined && !unmounting) {
                    setIsLoading(true);
                    const response = await reportingHttpService.getDealsSummaryAsync(startDate, endDate)
                    if (!unmounting) {
                        if (!response) {
                            throw new Error('Network error occurred.');
                        }
                        setDealsSummary(response.data);
                    }
                }
            } catch (error) {
                if (!unmounting) {
                    if (error.message.includes('Network error occurred.')) {
                        setCurrentNoDataProps(useNetworkErrorMessage);
                        toast.error(t('network-error.toast-message'));
                    } else {
                        toast.error(t('reporting.notifications.list-load.errors.generic'));
                    }
                }
            } finally {
            if (!unmounting) {
                setIsLoading(false);

            }
        }
        }

        fetchOverviewData();

        return () => {
            setUnmounting(true);
        };

    }, [startDate, endDate]);

    return (
        <>
            {isLoading ? (
                <LoadingIndicator/>
            ) : (
                dealsSummary && dealsSummary.deal_count ? (
                    <div style={{width: '100%', height: '100%'}}>
                        <LargeCardsContainer dealsSummary={dealsSummary} tabChange={tabChange} setUnmounting={setUnmounting}/>
                        <SmallCardsContainer dealsSummary={dealsSummary}/>
                    </div>
                ) : (
                    <NoData showIcon={"event_busy"} title={currentNoDataProps.title}
                            description={t(currentNoDataProps.description)}/>
                )
            )}
        </>
    );
};

export default Overview;