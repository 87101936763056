// React component imports
import { UserAccountMenu } from "./components/UserAccountMenu";
import { NotificationsDropDownMenu } from "./components/NotificationsDropDownMenu";
import { useState, useContext } from "react";

// MUI Components imports
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
} from "@mui/material";

import { useAuth0 } from "@auth0/auth0-react";

export const TopBar = () => {
  // todo: NikolaM, remove dummy data
  const settings = ["Notification1", "Notification2", "Notification3"];
  const { user } = useAuth0();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  // Set to open drop down menu for user settings
  const [userMenu, setUserMenu] = useState<null | HTMLElement>(null);

  // Handles user drop down menu
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenu(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar
        component="nav"
        position="sticky"
        elevation={0}
        color="inherit"
        className="header"
      >
        <Toolbar className="header-content"
        >
          <Box className="header-actions">

            <IconButton
                edge="start"
                color="inherit"
                aria-label="profile"
                onClick={handleOpen}
            >
                <span className="material-symbols-rounded size-24 header-icon" >person</span>
            </IconButton>
            <UserAccountMenu
              name={user?.name}
              email={user?.email}
              setUserMenu={setUserMenu}
              userMenu={userMenu}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};
