import {Button, Chip, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {PageTitleProps} from '../../typings/common.types';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import React from "react";

export const PageTitle = ({
                              pageTitle,
                              headerChip,
                              headerButtons,
                              isBreadcrumbAvailable = true,
                              customBreadcrumbs,
                              customBreadcrumbFunctionality,
                              pageTitleActions,
                              greetingMessage = null,
                          }: PageTitleProps) => {

    return (
        <Box className="page-title">
            <Box className="page-title-content" sx={{pb: 3}}>
                <Box className="page-title-header">
                    <Box>
                        <Typography variant="h5" className="page-title-name">{pageTitle}
                            {headerChip && (
                                <Chip sx={{marginLeft: "16px"}}
                                      label={headerChip.label}
                                      color={headerChip.color}
                                      icon={headerChip.icon}
                                      size={headerChip.size}
                                />
                            )}
                        </Typography>
                        {
                            greetingMessage && greetingMessage
                        }
                        {
                            isBreadcrumbAvailable && (
                                <Breadcrumbs customBreadcrumbs={customBreadcrumbs}
                                             customBreadcrumbFunctionality={customBreadcrumbFunctionality}
                                ></Breadcrumbs>
                            )
                        }
                    </Box>
                </Box>

                {pageTitleActions !== undefined && pageTitleActions}

                <Box className="page-title-actions">
                    {
                        headerButtons?.length && (
                            headerButtons.map(button => (
                                <Button key={button.name} disabled={button.disabled} onClick={() => button.onClicked()}
                                        variant="contained" size="medium" color={button.color} startIcon={<span
                                    className="material-symbols-rounded size-24">{button.icon}</span>}>{button.name}</Button>
                            ))
                        )
                    }
                </Box>

            </Box>
        </Box>
    )
}