import { styled } from '@mui/material';
import {
	ErrorOutlineOutlined,
	CheckCircleOutlineOutlined
} from '@mui/icons-material';
import creative_theme from './../../../theme/theme';

export const ErrorIcon = styled(ErrorOutlineOutlined)({
	color: creative_theme.palette.secondary.main,
	fontSize: '22px'
});

export const SuccessIcon = styled(CheckCircleOutlineOutlined)({
	color: creative_theme.palette.secondary.main,
	fontSize: '22px'
});
