import {injectable} from "tsyringe";
import {
    GetAllDealParams,
    IDealCreationRequest,
    IDealCreationResponse,
    IDealEditRequest,
    IDealEditResponse, IDealEditStatusRequest,
    IDealStatusUpdateRequest, IDealStatusUpdateResponse
} from "../../typings/api-models/deal.models";
import {httpClient} from "../httpClient";
import {IDealHttpService} from "./interfaces/iDealHttp.service";

@injectable()
export class DealHttpService implements IDealHttpService {

    getAllDeals = async (params: GetAllDealParams) => {
        return await httpClient.get(
            process.env.API_URL + '/deal', {
                params: params
            });
    }

    createNewDeal = async (bodyRequest: IDealCreationRequest) => {
        return await httpClient.post<IDealCreationResponse>('deal', bodyRequest);
    };

    getDeal = async (dealId: string) => {
        return await httpClient.get(process.env.API_URL + '/deal/' + dealId);
    }

    editDeal = async (dealId: string, bodyRequest: IDealEditRequest) => {
        return await httpClient.patch<IDealEditResponse>('deal/' + dealId, bodyRequest);
    }

    editDealStatus = async (dealId: string, bodyRequest: IDealEditStatusRequest) => {
        return await httpClient.patch<IDealEditResponse>('deal/' + dealId + '/status', bodyRequest);
    }
}

export default DealHttpService;