import {useEffect, useState} from 'react';
import {container} from 'tsyringe';
import {
    DataGridPremium,
    GridPaginationModel,
    GridSortModel,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbar,
    GridOverlay,
    GridSortDirection
} from "@mui/x-data-grid-premium";
import {ReportingHttpService} from "../../../../../utils/services/reportingHttpService";
import {IReportingHttpService} from "../../../../../utils/services/interfaces/IReportingHttp.service";
import {AxiosResponse} from 'axios';
import {IBaseDeal, IDealsResponse} from "../../../../../typings/api-models/deal.models";
import {iLdskHttpError} from "../../../../../typings/api-models/commonApi.models";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {
    dealTypes,
    toCurrency,
    toPercentage
} from "../../../../../utils/formatter";
import NoData from "../../../../common/NoData"
import {Box, Grid} from "@mui/material";
import {GetDealsPerformanceParams} from "../../models/Deal";
import SearchFilter from "../../../../common/Toolbar/SearchFilter";
import {useAuth0} from "@auth0/auth0-react";
import {subtractFixedWidth} from "../../../../../utils/responsivePages";
import {NoRowsOverlayPropsOverrides} from "@mui/x-data-grid";
import NetworkErrorMessages from "../../../../../utils/networkErrorMessages";

const DealsList = ({startDate, endDate, unmounting, setUnmounting}: { startDate?: Date, endDate?: Date, unmounting: any, setUnmounting: any }) => {
    const reportingHttpService: IReportingHttpService = container.resolve(ReportingHttpService);
    const [searchText, setSearchText] = useState<string>("");

    const DEFAULT_PAGE_SIZE = 10;
    const DEFAULT_SORT_FIELD = "revenue";
    const DEFAULT_SORT_DIRECTION: GridSortDirection = "desc";

    const [deals, setDeals] = useState<IBaseDeal[]>([]);
    const [totalDeals, setTotalDeals] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    });
    const [isLoading, setLoading] = useState(true);
    const [isDateChanged, setIsDateChanged] = useState(false);
    const [currentSortingField, setSortingField] = useState(DEFAULT_SORT_FIELD);
    const [currentSortingDirection, setSortingDirection] = useState(DEFAULT_SORT_DIRECTION);
    const [t, i18n] = useTranslation('locale');
    const useNetworkErrorMessage = NetworkErrorMessages();

    const noData: NoRowsOverlayPropsOverrides = {
        showIcon: "filter_list_off",
        title: t('creatives.data-grid.search-nodata-title'),
        description: t('creatives.data-grid.search-nodata-description')
    };

    const [currentNoDataProps, setCurrentNoDataProps] = useState(noData);

    const handleSortModelChange = (sortModel: GridSortModel) => {
        // Every third click, it resets the sorting of the column to its default
        if (sortModel.length !== 0) {
            setSortingField(sortModel[0].field);
            setSortingDirection(sortModel[0].sort || 'asc');
        } else {
            setSortingField(DEFAULT_SORT_FIELD);
            setSortingDirection(DEFAULT_SORT_DIRECTION);
        }
        setUnmounting(false);
    }

    const NoDataFunc = () => {
        if (isDateChanged && deals.length === 0 && !isLoading) {
            return (<NoData showIcon={"search_off"} title={currentNoDataProps.title}
                            description={t(currentNoDataProps.description)}></NoData>)
        }
        return (<NoData showIcon={"search_off"} title={t('creatives.data-grid.search-nodata-title')}
                        description={t('creatives.data-grid.search-nodata-description')}></NoData>)
    }
    const [isInputSearchFocused, setIsInputSearchFocused] = useState(false);
    const [debouncedSearchText, setDebouncedSearchText] = useState('');
    const CustomToolbar = () => {
        return (
                <GridToolbarContainer sx={{p: 2}}>
                    <Grid container spacing={2} >
                        <Grid item xs={6}>
                            <SearchFilter placeholder={t("search.deals")} searchText={searchText}
                                          setSearchText={setSearchText}
                                          isInputFocused={isInputSearchFocused}
                                          setInputFocused={setIsInputSearchFocused}
                                          debouncedSearchText={debouncedSearchText}
                                          setDebouncedSearchText={setDebouncedSearchText}
                                          setUnmounting={setUnmounting}
                            />
                        </Grid>
                    </Grid>
                </GridToolbarContainer>
        );
    };

    const columns = [
        {
            field: 'deal_name',
            headerName: 'Deal Name',
            minWidth: 210,
            flex: 1,
            sortable: false,
        },
        {
            field: 'deal_id',
            headerName: 'Deal ID',
            minWidth: 200,
            flex: 1,
            sortable: false,
        },
        {
            field: 'deal_type',
            headerName: 'Deal Type',
            minWidth: 150,
            flex: 1,
            sortable: true,
            valueGetter: ({row}: { row: IBaseDeal }) => {
                return dealTypes[row.deal_type];
            }
        },
        {
            field: 'advertiser_domain',
            headerName: 'Advertiser',
            minWidth: 150,
            flex: 1,
            sortable: true,
        },
        {
            field: 'dsp_name',
            headerName: 'DSP',
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueGetter: ({row}: { row: IBaseDeal }) => {
                return row.dsp.name;
            }
        },
        {
            field: 'played_impressions',
            headerName: 'Impressions',
            minWidth: 150,
            flex: 1,
            sortable: true,
            valueGetter: ({row}: { row: IBaseDeal }) => {
                return row.played_impressions.toLocaleString();
            }
        },
        {
            field: 'bid_rate',
            headerName: 'Bid rate',
            minWidth: 105,
            flex: 1,
            sortable: true,
            valueGetter: ({row}: { row: IBaseDeal }) => {
                return toPercentage(row.winning_bids / row.total_bids);
            },
        },
        {
            field: 'winning_bids',
            headerName: 'Bid requests',
            minWidth: 140,
            flex: 1,
            sortable: true,
            valueGetter: ({row}: { row: IBaseDeal }) => {
                return row.winning_bids.toLocaleString();
            },
        },
        {
            field: 'fill_rate',
            headerName: 'Fill rate',
            minWidth: 105,
            flex: 1,
            sortable: true,
            valueGetter: ({row}: { row: IBaseDeal }) => {
                return toPercentage(row.plays / row.winning_bids);
            },
        },
        {
            field: 'ecpm',
            headerName: 'eCPM',
            minWidth: 110,
            flex: 1,
            sortable: false,
            valueGetter: ({row}: { row: IBaseDeal }) => {
                return toCurrency(row.ecpm);
            }
        },
        {
            field: 'revenue',
            headerName: 'Revenue',
            minWidth: 140,
            flex: 1,
            sortable: true,
            valueGetter: ({row}: { row: IBaseDeal }) => {
                return toCurrency(row.played_revenue);
            }
        },
    ];

    const handlePaginationModelChange = (newModel: GridPaginationModel) => {
        setPaginationModel(newModel);
        setUnmounting(false);
    };

    useEffect(() => {
        (startDate || endDate) && setIsDateChanged(true);
        (searchText) && setIsDateChanged(false);

        const fetchDeals = async () => {
            try {
                if (startDate != undefined && endDate != undefined) {
                    setLoading(true);
                    var params: GetDealsPerformanceParams = {
                        startDate: startDate,
                        endDate: endDate,
                        searchText: searchText.trim(),
                        page: paginationModel.page + 1,
                        take: paginationModel.pageSize,
                        sortDirection: currentSortingDirection,
                        sortBy: currentSortingField
                    }
                    const response = await reportingHttpService.getAllDealsAsync(params);
                    if (!unmounting) {
                        if (!response) {
                            throw new Error('Network error occurred.');
                        }
                        setDeals(response.data.deals);
                        setTotalDeals(response.data?.count || (response.data?.deals || []).length);
                    }
                }
            } catch (error) {
                if (!unmounting) {
                    if (error.message.includes('Network error occurred.')) {
                        setCurrentNoDataProps(useNetworkErrorMessage);
                        toast.error(t('network-error.toast-message'));
                    } else {
                        toast.error(t('reporting.notifications.list-load.errors.generic'));
                    }
                }
            } finally {
                if (!unmounting) {
                    setLoading(false);
                }
            }
        }

        fetchDeals();

        return () => {
            setUnmounting(true);
        };

    }, [startDate, endDate, searchText, paginationModel, currentSortingField, currentSortingDirection]);

    return (
        <div style={{height: deals.length == 0 ? 400 : 'auto', width: `calc(100vw - ${subtractFixedWidth()}px)`}}>
            <DataGridPremium
                autoHeight={deals.length != 0}
                disableColumnResize={true}
                disableColumnReorder={true}
                disableColumnMenu={true}
                disableRowSelectionOnClick={true}
                rowHeight={52}
                rows={deals}
                getRowId={(row: any) => row.deal_id + row.advertiser_domain}
                rowCount={totalDeals}
                columns={columns}
                pagination
                pageSizeOptions={[10, 25, 50, 100]}
                loading={isLoading}
                paginationMode='server'
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
                slots={{
                    toolbar: CustomToolbar,
                    noResultsOverlay: NoDataFunc,
                    noRowsOverlay: NoDataFunc,
                }}
                sortingOrder={['asc', 'desc']}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                initialState={{
                    sorting: {
                        sortModel: [{field: currentSortingField, sort: currentSortingDirection}],
                    }
                }}
            />
        </div>
    );
}

export default DealsList;