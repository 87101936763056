import { useEffect } from 'react';

export function setTabTitle(title: string) {
    useEffect(() => {
        const prevTitle = document.title;
        document.title = `LDSK | ${title}`;

        return () => {
            document.title = prevTitle;
        }
    })
}