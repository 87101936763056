import {
    Alert,
    AlertTitle,
    Box,
    Card, FormControl,
    Grid,
    IconButton, MenuItem, Select,
    SelectChangeEvent,
    Tooltip,
    Typography,
    useTheme,
    InputLabel, Dialog, DialogContent, DialogActions, Button
} from '@mui/material';
import {
    DataGridPremium,
    GridColDef,
    GridPaginationModel,
    GridSortModel,
    GridToolbarContainer
} from '@mui/x-data-grid-premium';

import {AxiosResponse, request} from 'axios';
import React, {useEffect, useMemo, useState} from 'react';
// Lumina components
import {container} from 'tsyringe';
import {iLdskHttpError} from '../../../typings/api-models/commonApi.models';
import DealHttpService from '../../../utils/services/dealHttpService';
import {IDealHttpService} from '../../../utils/services/interfaces/iDealHttp.service';
import {setTabTitle} from "../../common/tabTitle";
import EditIcon from '@mui/icons-material/Edit';
import {
    GetAllDealParams,
    IBaseDeal,
    IDealEditRequest, IDealEditStatusRequest,
    IDealListItem,
    IDealListResponse, IDealStatusUpdateRequest
} from '../../../typings/api-models/deal.models';
import toast from 'react-hot-toast';
import Toaster from '../../common/Toaster/Toaster';
import {
    creativeStatusMap,
    dealStatusMap,
    dealTypes,
    dealTypesMap,
    getCurrencyFormatter, mimeTypes
} from '../../../utils/formatter';
import { useNavigate } from 'react-router-dom';

import SearchFilter from "../../common/Toolbar/SearchFilter";
import NoData from "../../common/NoData";

import { SnackbarOrigin } from '@mui/material/Snackbar';
import MultipleSelectFilter from "../../common/Toolbar/MultipleSelectFilter";
import Chip from "@mui/material/Chip";
import FilterChip from "../../common/Toolbar/FilterChip";
import dayjs from "dayjs";
import {NoRowsOverlayPropsOverrides} from "@mui/x-data-grid";
import {debounce} from "lodash";

import CopyToClipboard from "../../common/CopyToClipboard/CopyToClipboard";
import CopyToClipboardSnackbar from "../../common/CopyToClipboard/CopyToClipboardSnackbar";
import {subtractFixedWidth} from "../../../utils/responsivePages";
import {
    DealMutationParams,
    DealStatus,
    DealStatusDialogParams,
    defaultDealStatusDialog,
    getDealStatusFromString
} from "./models/Deals";
import {useTranslation} from "react-i18next";
import getDealStatusStyleProperties from "./components/DealStatusProperties";
import {s} from "msw/lib/glossary-de6278a9";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import {useMutation} from "react-query";
import CreativesListService from "../../../utils/services/CreativesListService";
import {Creative, Status} from "../CreativesList/models/CreativesList";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import {WatchLater} from "@mui/icons-material";
import {CustomAlert} from "../../common/CustomAlert";
import {Deal} from "../Reporting/models/Deal";
import NetworkErrorMessages from "../../../utils/networkErrorMessages";

export interface SnackbarState extends SnackbarOrigin {
    open: boolean;
}

const DEFAULT_SORT_FIELD = "";
const DEFAULT_SORT_DIRECTION = 'asc';

const DealsGridView = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [t, i18n] = useTranslation('locale');

    setTabTitle(t('deals.tabTitle'));

    const [deals, setDeals] = useState<IDealListItem[]>([]);
    const [totalDeals, setTotalDeals] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [typesFilter, setTypesFilter] = useState<string[]>(Array.from(dealTypesMap.keys()));
    const [statusFilter, setStatusFilter] = useState<DealStatus[]>(Array.from(dealStatusMap.keys()));

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [currentSortingField, setSortingField] = useState(DEFAULT_SORT_FIELD);
    const [currentSortingDirection, setSortingDirection] = useState(DEFAULT_SORT_DIRECTION);
    const [searchText, setSearchText] = useState<string>("");
    const [snackbarState, setSnackbarState] = useState<SnackbarState>({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    });
    const {vertical, horizontal, open} = snackbarState;
    const [isInputSearchFocused, setIsInputSearchFocused] = useState(false);
    const [debouncedSearchText, setDebouncedSearchText] = useState('');
    const useNetworkErrorMessage = NetworkErrorMessages();

    const [isStatusInEditMode, setIsStatusInEditMode] = useState(false);
    const [selectedStatusRowId, setSelectedStatusRowId] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [statusChangeDialogOpen, setStatusChangeDialogOpen] = useState(false);
    const [statusDialogParams, setStatusDialogParams] = useState(defaultDealStatusDialog);

    const handleAuctionTypeFilterDelete = (value: string) => {
        let auctionTypes = typesFilter.filter((filter) => filter !== value);
        if (auctionTypes.length == 0) {
            auctionTypes = Array.from(dealTypesMap.keys());
        }
        setTypesFilter(auctionTypes);
        setUnmounting(false);
    };

    const renderAuctionTypeChips = () => {
        if (typesFilter.length == 0 || typesFilter.length == dealTypesMap.size) {
            return null;
        }
        return (
            <Grid item>
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1}}>
                    {typesFilter.map((filter) => (
                        <FilterChip key={`creative_filter_auction_type_${filter}`}
                                    filterVariant={t("deals.filter.auction-type")}
                                    filterStatus={dealTypes[filter]}
                                    onDelete={() => handleAuctionTypeFilterDelete(filter)}/>
                    ))}
                </Box>
            </Grid>
        );
    };

    const handleStatusFilterDelete = (value: DealStatus) => {
        let statuses = statusFilter.filter((filter) => filter !== value);
        if (statuses.length == 0) {
            statuses = Array.from(dealStatusMap.keys());
        }
        setStatusFilter(statuses);
        setUnmounting(false);
    };

    const renderStatusChips = () => {
        if (statusFilter.length == 0 || statusFilter.length == dealStatusMap.size) {
            return null;
        }

        return (
            <Grid item>
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1}}>
                    {statusFilter.map((filter) => (
                        <FilterChip key={`deals_filter_status_${filter}`}
                                    filterVariant={t('deals.filter.status')}
                                    filterStatus={dealStatusMap.get(filter) ?? ""}
                                    onDelete={() => handleStatusFilterDelete(filter)}/>
                    ))}
                </Box>
            </Grid>
        );
    };

    const CustomToolbar = () => {
        const statusChipsRender = renderStatusChips();
        const auctionTypeChipsRender = renderAuctionTypeChips();
       if (statusChipsRender || auctionTypeChipsRender)
            return (
                    <GridToolbarContainer className="grid-toolbar-chips" sx={{p: 2}}>
                        <Grid container spacing={2}>
                            {statusChipsRender}
                            {auctionTypeChipsRender}
                        </Grid>
                    </GridToolbarContainer>
            );
        return null;
    };

    const dealHttpService: IDealHttpService = container.resolve(DealHttpService);

    const noDataFromSearchProps: NoRowsOverlayPropsOverrides = {
        showIcon: "search_off",
        title: t('deals.list-load.empty-search.title'),
        description: t('deals.list-load.empty-search.sub-title')
    };

    const noDataFromFilterProps: NoRowsOverlayPropsOverrides = {
        showIcon: "filter_list_off",
        title: t('deals.list-load.empty-filter.title'),
        description: t('deals.list-load.empty-filter.sub-title')
    };
    const [currentNoDataProps, setCurrentNoDataProps] = useState(noDataFromSearchProps);

    const handleEditDeal = (dealId: string) => {
        navigate("/deals/edit/" + dealId);
    };

    const handleSortModelChange = (sortModel: GridSortModel) => {
        // Every third click, it resets the sorting of the column to its default
        if (sortModel.length !== 0) {
            setSortingField(sortModel[0].field);
            setSortingDirection(sortModel[0].sort || 'asc');
        } else {
            setSortingField(DEFAULT_SORT_FIELD);
            setSortingDirection(DEFAULT_SORT_DIRECTION);
        }
        setUnmounting(false);
    }

    const handlePaginationModelChange = (newModel: GridPaginationModel) => {
        setPaginationModel(newModel);
        setUnmounting(false);
    };

    const [unmounting, setUnmounting] = useState(false);
    const fetchDealsData = async () => {
        try {
            // If you go from the creative list and it has an issue it will do the toaster in this page aswell
            // Just removing all the previous toasts
            toast.remove();
            let auctionTypes = typesFilter.length == dealTypesMap.size ? [] : typesFilter;
            const params: GetAllDealParams = {
                page: paginationModel.page + 1,
                take: paginationModel.pageSize,
                search: searchText.trim(),
                sortDirection: currentSortingDirection,
                sortBy: currentSortingField,
                auctionTypes: auctionTypes
            };
            setIsLoading(true);

            let currentNoDataPropsTemp = (statusFilter || typesFilter.length > 0) ? noDataFromFilterProps : noDataFromSearchProps;
            setCurrentNoDataProps(currentNoDataPropsTemp);

            if (statusFilter && statusFilter.length != dealStatusMap.size) {
                params.statuses = statusFilter;
            }

            const response = await dealHttpService.getAllDeals(params);

            if (!unmounting) {
                if (!response) {
                    throw new Error('Network error occurred.');
                }
                const {data} = response;
                setDeals(response.data?.deals || []);
                setTotalDeals(
                    response.data?.count || (response.data?.deals || []).length
                );
            }
        } catch (error) {
            if (!unmounting) {
                if (error.message.includes('Network error occurred.')) {
                    setCurrentNoDataProps(useNetworkErrorMessage);
                    toast.error(t('network-error.toast-message'));
                } else {
                    toast.error(t('deals.notifications.list-load.errors.generic'));
                }
            }
        } finally {
            if (!unmounting) {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchDealsData();

        return () => {
            setUnmounting(true);
        };

    }, [paginationModel, currentSortingField, currentSortingDirection, searchText, statusFilter,
        useMemo(() => JSON.stringify(typesFilter), [typesFilter])]);


    const getGridHeight = () => {
        //if there are deals have auto height
        if (deals.length > 0) {
            return "auto";
        }
        //if some filter is selected
        if (statusFilter.length > 0 || typesFilter.length > 0) {
            return 400;
        }
        return 350;
    }


    const handleClickOnStatusChipElement = (rowId: string) => {
        setIsStatusInEditMode(true);
        setSelectedStatusRowId(rowId);
    };

    const handleStatusChange = (newValue: string) => {
        setSelectedStatus(newValue);
        handleModalDialogText(newValue);
        setStatusChangeDialogOpen(true);
    };

    const handleStatusChangeDialogClose = () => {
        setIsStatusInEditMode(false);
        setSelectedStatusRowId('');
        setSelectedStatus('');
        setStatusDialogParams(defaultDealStatusDialog);
        setStatusChangeDialogOpen(false);
    };

    const handleCloseOnStatusSelectElement = () => {
        setIsStatusInEditMode(false);
    };

    const handleConfirmStatusChange = () => {

        let request : IDealEditStatusRequest = {
            status: selectedStatus
        }

        mutation.mutate({dealId: selectedStatusRowId, request});
        handleStatusChangeDialogClose();
    };

    const handleModalDialogText = (selectedStatus: string) => {
        const selectedStatusToLower = selectedStatus.toLowerCase();
        const dealStatusDialog: DealStatusDialogParams = {
            title: t('deals.modal-dialog.' + selectedStatusToLower + '.title'),
            description: t('deals.modal-dialog.' + selectedStatusToLower + '.description'),
            confirmationButton: t('deals.modal-dialog.' + selectedStatusToLower + '.confirmationButton'),

        }
        setStatusDialogParams(dealStatusDialog);
    }

    const mutation = useMutation(
        async ({dealId, request}: DealMutationParams) => {
            return await dealHttpService.editDealStatus(dealId, request);
        },
        {
            onError: (error, request) => {
                toast.custom(
                    <CustomAlert severity={"error"} title={t('deals.notifications.status-change.title')}
                                 message={t('deals.notifications.status-change.error')} style={{maxWidth: "320px"}}></CustomAlert>
                );
            },
            onSuccess: (response, {dealId}) => {
                let dealName = response.data.deal_name;
                let newStatus = t('deals.notifications.status-change.status.' + response.data.status.toLowerCase());
                let dealCurrentStatus = deals.find(deal => deal.deal_id == dealId)?.status.toLowerCase();
                let message = t('deals.notifications.status-change.body', {dealName, dealCurrentStatus, newStatus});

                toast.custom(
                    <CustomAlert severity={"success"} title={t('deals.notifications.status-change.title')}
                                 message={message} style={{maxWidth: "320px"}}></CustomAlert>,
                );

                setDeals((prevState) => {
                    const updatedStatusEnum = getDealStatusFromString(response.data.status);

                    if (statusFilter && updatedStatusEnum !== undefined && !statusFilter.includes(updatedStatusEnum)) {
                        // If statusFilter is on and updatedStatus is not included, re-fetch the deals list to avoid wrong paging
                        fetchDealsData();
                        return;
                    }

                    // Update the status of the specified dealId
                    return prevState.map((el: IDealListItem) => ({
                        ...el,
                        status: el.deal_id === dealId ? updatedStatusEnum || el.status : el.status,
                    }));
                });
            }
        }
    );

    type CustomColDef = GridColDef & { hide?: boolean };

    const buildStatusCssElement = (row: any) => {
        const {backgroundColor, textColor} = getDealStatusStyleProperties(row.status);
        let statusLabel = t("deals.statuses." + row.status.toLowerCase())
        return <>
            {isStatusInEditMode && row.deal_id == selectedStatusRowId ? (
                <FormControl fullWidth>
                    <Select
                        open={isStatusInEditMode}
                        onClose={handleCloseOnStatusSelectElement}
                        value={row.status}
                        onChange={(e) => handleStatusChange(e.target.value)}
                        inputProps={{'aria-label': 'Without label'}}
                    >
                        {Array.from(dealStatusMap).map(([key, value]) => (
                            <MenuItem value={key}>{t("deals.statuses." + value.toLowerCase())}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <div onClick={() => handleClickOnStatusChipElement(row.deal_id)}>
                    <Chip
                        label={statusLabel}
                        size="small"
                        className='deal-status-label'
                        style={{backgroundColor: backgroundColor, color: textColor}}
                    />
                </div>
            )}
        </>
    }

    const columns: CustomColDef[] = [
        {
            field: "deal_name",
            headerName: t('deals.fields.deal-name'),
            sortable: true,
            cellClassName: "cell-type-deal-name",
            minWidth: 300,
            flex: 1,
            valueGetter: ({row}) => row?.deal_name
        },
        {
            field: "deal_id",
            headerName: t('deals.fields.deal-id'),
            sortable: false,
            minWidth: 350,
            flex: 1,
            cellClassName: "cell-type-deal-id",

            renderCell: ({row, row: {status, id}}) => (
                <CopyToClipboard id={'deals-deal-id-'+row?.deal_id}
                                 valueToBeCloned={row?.deal_id}
                                 setSnackbarState={setSnackbarState}
                                 divClassName={'deal-name-info'}/>
            )
        },
        {
            field: "auction_type",
            headerName: t('deals.fields.auction-type'),
            sortable: true,
            minWidth: 100,
            cellClassName: "cell-type-auction-type",

            flex: 0.5,
            valueGetter: ({row}) => {
                return dealTypes[row.deal_type];
            }
        },
        {
            field: "status",
            headerName: t('deals.fields.status'),
            sortable: true,
            minWidth: 130,
            cellClassName: "cell-type-status",

            flex: 0.5,
            renderCell: ({row}) => {
                return buildStatusCssElement(row);
            }
        },
        {
            field: "cpm",
            headerName: t('deals.fields.cpm'),
            sortable: false,
            minWidth: 100,
            flex: 0.5,
            cellClassName: "cell-type-cpm",
            
            valueGetter: ({row}) => getCurrencyFormatter(row?.currency).format(row?.floor_cpm)
        },
        {
            field: "actions",
            headerName: t('deals.fields.actions'),
            sortable: false,
            cellClassName: "cell-type-actionBtns",

            width: 140,
            renderCell: ({row, row: {status, id}}) => (
                <Tooltip title={t('deals.edit-tooltip-title')}>
                    <IconButton aria-label="edit" onClick={e => handleEditDeal(row?.deal_id)} className='edit-deal-btn'>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
            )
        }
    ];

    return (
        <>
            <Card sx={{border: "none", boxShadow: "none"}}>
                <Box className="table-card-header">
                    <Box className="table-content">
                        <Grid container className="grid-toolbar" sx={{p: 2}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <SearchFilter placeholder={t("deals.search")} searchText={searchText}
                                                  setSearchText={setSearchText}
                                                  isInputFocused={isInputSearchFocused}
                                                  setInputFocused={setIsInputSearchFocused}
                                                  debouncedSearchText={debouncedSearchText}
                                                  setDebouncedSearchText={setDebouncedSearchText}
                                                  setUnmounting={setUnmounting}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2} justifyContent="right">
                                        <Grid item xs={4}>
                                            <MultipleSelectFilter identifier="deals-status-filter-select"
                                                                  placeholder={t("deals.filter.status")}
                                                                  values={dealStatusMap}
                                                                  selectedValues={statusFilter}
                                                                  setSelectedValues={setStatusFilter}
                                                                  isAllSelectionAllowed={false}
                                                                  isEmptyStateAllowed={false}
                                                                  setUnmounting={setUnmounting}/>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <MultipleSelectFilter
                                                identifier="auction-type"
                                                placeholder={t("deals.filter.auction-type")}
                                                values={dealTypesMap}
                                                selectedValues={typesFilter}
                                                setSelectedValues={setTypesFilter}
                                                isAllSelectionAllowed={false}
                                                isEmptyStateAllowed={false}
                                                setUnmounting={setUnmounting}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div style={{height: getGridHeight(), width: `calc(100vw - ${subtractFixedWidth()}px)`}}>
                            <DataGridPremium
                                autoHeight={deals.length != 0}
                                disableColumnResize={true}
                                disableColumnReorder={true}
                                disableColumnMenu={true}
                                disableRowSelectionOnClick={true}
                                rowHeight={52}
                                rows={deals}
                                rowCount={totalDeals}
                                columns={columns}
                                getRowId={(row) => row?.deal_id}
                                pageSizeOptions={[10, 25, 50, 100]}
                                pagination
                                className="grid-data"
                                loading={isLoading}
                                paginationMode='server'
                                paginationModel={paginationModel}
                                onPaginationModelChange={handlePaginationModelChange}
                                sortingOrder={['asc', 'desc']}
                                sortingMode="server"
                                onSortModelChange={handleSortModelChange}
                                initialState={{
                                    sorting: {
                                        sortModel: [{field: DEFAULT_SORT_FIELD, sort: DEFAULT_SORT_DIRECTION}],
                                    }
                                }}
                                slots={{
                                    toolbar: CustomToolbar,
                                    noRowsOverlay: NoData,
                                    noResultsOverlay: NoData
                                }}
                                slotProps={{
                                    toolbar: {},
                                    noRowsOverlay:currentNoDataProps,
                                    noResultsOverlay: currentNoDataProps
                                }}
                            />
                            <Dialog
                                fullWidth={true}
                                maxWidth='sm'
                                open={statusChangeDialogOpen}
                                onClose={() => handleStatusChangeDialogClose()}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {statusDialogParams.title}
                                </DialogTitle>
                                <DialogContent dividers>
                                    <DialogContentText id="alert-dialog-description">
                                        {statusDialogParams.description}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => handleStatusChangeDialogClose()}>
                                        {t('deals.modal-dialog.cancel-button')}
                                    </Button>
                                    <Button onClick={() => handleConfirmStatusChange()} autoFocus variant="contained">
                                        {statusDialogParams.confirmationButton}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </Box>
                    <CopyToClipboardSnackbar setSnackbarState={setSnackbarState} snackbarState={snackbarState} />
                </Box>
            </Card>
            <Toaster/>
        </>
    )
};

export default DealsGridView;

