import React, {useEffect, useState} from 'react';
import {
    Card,
    CardHeader,
    Typography,
    Box,
    Chip,
    Button,
    Stack,
    Checkbox,
    Grid,
    Autocomplete,
    TextField,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions, Alert, AlertTitle
} from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {Creative, IABCategory, IABCategoryTreeNode} from "../../models/CreativesList";
import CreativesListService from "../../../../../utils/services/CreativesListService";
import toast from "react-hot-toast";
import {AxiosResponse} from "axios";
import {iLdskHttpError} from "../../../../../typings/api-models/commonApi.models";
import {useTranslation} from "react-i18next";
import LoadingIndicator from "../../../../common/LoadingIndicator";

interface Props {
    creative: Creative,
    updateCreative: (updatedCreative: Creative) => void;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const IABCategoriesPreview = ({creative, updateCreative}: Props) => {
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isSearchDisabled, setIsSearchDisabled] = useState<boolean>(true);
    const [newValues, setNewValues] = useState<IABCategoryTreeNode[]>([]);
    const [allCategories, setAllCategories] = useState<IABCategory[]>([]);
    const [options, setOptions] = useState<IABCategoryTreeNode[]>([]);
    const [cancelChanges, setCancelChanges] = useState(false);
    const [open, setOpen] = useState(false);
    const [refreshOptions, setRefreshOptions] = useState(false);
    const [t, i18n] = useTranslation('locale');

    const dspid = creative.advertiser.dsp_provider.id;

    const isOptionEqualToValue = (option, value) => option.id === value.id;

    const getMaxGridWidth = () => {
        return document.getElementsByClassName('page-title')[0].clientWidth;
    };

    const editingMode = () => {
        setIsEditMode(true);
        setIsSearchDisabled(false);
    };

    const changeEditorValues = (event: any, value: any) => {
        const newSelectedIds = value.map((option) => option.id);
        setNewValues(value);

        const updatedOptions = options.map((option) => ({
            ...option,
            included: !option.disabled ? newSelectedIds.includes(option.id) : option.included,
        }));

        setOptions(updatedOptions);

    };

    const handleDialogClose = () => {
        setCancelChanges(false);
    }
    const resetEditor = () => {
        let previousCategoriesIds = creative.categories.filter(cat => cat.origin != 1).map(cat => cat.category_id);
        let newCategoriesIds = newValues.map(cat => cat.id);

        const removedIds = previousCategoriesIds.filter(id => !newCategoriesIds.includes(id));
        const addedIds = newCategoriesIds.filter(id => !previousCategoriesIds.includes(id));

        if (removedIds.length > 0 || addedIds.length > 0) {
            setCancelChanges(true);
        }

        setNewValues(options.filter(option => option.included && !option.disabled));
        setIsEditMode(false);
        setIsSearchDisabled(true);
    }

    const submitCategoriesChanges = async () => {

        setIsEditMode(false);
        setIsSearchDisabled(true);

        let selectedCategoriesIds = newValues.map(cat => cat.id);
        let previousCategoriesIds = creative.categories.filter(cat => cat.origin != 1).map(cat => cat.category_id);
        let newCategoriesIds = selectedCategoriesIds.filter(id => !previousCategoriesIds.includes(id));
        let removedCategoriesIds = previousCategoriesIds.filter(id => !selectedCategoriesIds.includes(id));

        if (newCategoriesIds.length > 0) {
            await CreativesListService.addCategories(creative.id, newCategoriesIds);
        }

        if (removedCategoriesIds.length > 0) {
            await CreativesListService.deleteCategories(creative.id, removedCategoriesIds);
        }

        const response = await CreativesListService.getById(creative.id);

        if (response.status === 200) {
            toast.custom(
                <Alert severity="info">
                    <AlertTitle>{t('creatives.notifications.categories-update.title')}</AlertTitle>
                    {t('creatives.notifications.categories-update.body')}
                </Alert>
            );

            updateCreative(response.data);
        } else {
            toast.error(t('creatives.notifications.categories-update.errors.generic'));
        }
    }

    const handleDialogAccept = () => {
        setRefreshOptions(!refreshOptions);
        setCancelChanges(false);
        setIsEditMode(false);
        setIsSearchDisabled(true);
    }
    const fillCategoryTree = (allCategories: IABCategory[], parentCategory: IABCategoryTreeNode) => {
        const categoriesForParent = allCategories.filter(category => category.parent?.id == parentCategory.id);
        let nodeOptions = [];
        if (categoriesForParent.length == 0) return nodeOptions;
        categoriesForParent.forEach(Rootcategory => {
            let currentNode = {
                label: Rootcategory.code + ' ' + Rootcategory.name,
                id: Rootcategory.id,
                value: Rootcategory.id,
                name: Rootcategory.name,
                groupName: parentCategory.groupName,
                included: creative.categories.some(cat => cat.category_id === Rootcategory.id),
                tooltip: parentCategory.name,
                code: Rootcategory.code,
                parent: null,
                disabled: creative.categories.some(cat => cat.category_id === Rootcategory.id && cat.origin == 1),
            }
            nodeOptions.push(currentNode)
            const childNodeOptions = fillCategoryTree(allCategories, currentNode);
            nodeOptions = nodeOptions.concat(childNodeOptions);
        })
        return nodeOptions;
    }

    useEffect(() => {
        (async () => {
            const categoriesWithoutParent = allCategories.filter(category => category.parent == null);
            let nodeOptions = [];
            categoriesWithoutParent.forEach(Rootcategory => {
                let currentNode = {
                    label: Rootcategory.code + ' ' + Rootcategory.name,
                    id: Rootcategory.id,
                    value: Rootcategory.id,
                    name: Rootcategory.name,
                    groupName: Rootcategory.name,
                    included: creative.categories.some(cat => cat.category_id === Rootcategory.id),
                    tooltip: '',
                    code: Rootcategory.code,
                    parent: null,
                    disabled: creative.categories.some(cat => cat.category_id === Rootcategory.id && cat.origin == 1),
                }
                nodeOptions.push(currentNode)
                const childNodeOptions = fillCategoryTree(allCategories, currentNode);
                nodeOptions = nodeOptions.concat(childNodeOptions);
            })
            setOptions(nodeOptions);
            setNewValues(nodeOptions.filter(option => option.included && !option.disabled));
        })();
    }, [allCategories,refreshOptions]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await CreativesListService.getCategories(dspid)
                .then((response: AxiosResponse) => {
                    if (!response) {
                        throw new Error('Network error occurred.');
                    }
                    const {data} = response;
                    setAllCategories(data.categories);
                    setIsLoading(false);
                })
                .catch((errorResponse: iLdskHttpError) => {
                    setIsLoading(false);
                    if (errorResponse.message.includes('Network error occurred.')) {
                        toast.error(t('network-error.toast-message'));
                    } else {
                        toast.error(t('creatives.notifications.list-load.errors.generic'));
                    }
                });
        })()
    }, []);

    return (
        <>
            {isLoading ? (
                <LoadingIndicator/>
            ) : (
            <Box sx={{width: getMaxGridWidth()}}>
            <Dialog
                open={cancelChanges}
                onClose={handleDialogClose}
            >
                <DialogTitle>
                    <Typography>{t('creativeDetails.categoriesPreview.dialog.title')}</Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        {t('creativeDetails.categoriesPreview.dialog.content')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" size="medium" color="primary" onClick={handleDialogClose}>
                        {t('creativeDetails.categoriesPreview.dialog.cancel-button')}
                    </Button>
                    <Button variant="contained" size="medium" color="primary" onClick={handleDialogAccept}>
                        {t('creativeDetails.categoriesPreview.dialog.save-button')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Card sx={{display: 'flex', flexDirection: 'column', m: 'auto', alignSelf: 'stretch'}}>
                <CardHeader
                    title={<Typography variant="h6">{t("creativeDetails.categoriesPreview.title")}</Typography>}
                    action={
                        isEditMode ? (
                            <Chip
                                label={t("creativeDetails.categoriesPreview.editChip")}
                                sx={{mr: '9px'}}
                                size="small"
                            />
                        ) : (
                            <Button
                                onClick={editingMode}
                                sx={{mr: '9px'}}
                                variant="outlined"
                                size="small"
                                className='edit-categories-btn'
                            >
                                {t("creativeDetails.categoriesPreview.editButton")}
                            </Button>
                        )
                    }
                />
                <Box sx={{p: '16px', borderTop: '1px solid #e2e3e4', borderBottom: '1px solid #e2e3e4'}}>
                    <Stack direction="row" spacing={1} sx={{mb: '24px', flexWrap: 'wrap'}}>
                        {
                            creative.categories.length > 0 ?
                                (creative.categories.filter((item) => item.origin == 1).map(item =>
                                        <Chip
                                            key={Math.random()}
                                            label={`${item.code} ${item.name}`}
                                            size="small"
                                            className="CategoryChip"
                                        />)
                                ) : (
                                    <Stack color="text.secondary" direction="row" spacing={1}>
                                        <span className="material-symbols-rounded">report_off</span>
                                        <Typography>
                                            {t("creativeDetails.categoriesPreview.noCategories")}
                                        </Typography>
                                    </Stack>
                                )
                        }
                    </Stack>
                    <Box>
                        <Typography sx={{mb: '16px'}}>
                            {t("creativeDetails.categoriesPreview.searchLabel")}
                        </Typography>
                        <Grid container>
                            <Grid item xs={6}>
                                <Autocomplete
                                    className='category-labels-autocomplete'
                                    id="categories-editor"
                                    disabled={isSearchDisabled}
                                    value={newValues}
                                    onChange={changeEditorValues}
                                    multiple
                                    disablePortal={true}
                                    autoComplete
                                    open={open}
                                    onOpen={() => {
                                        if (!isSearchDisabled) {
                                            setOpen(true);
                                        }
                                    }}
                                    onClose={() => setOpen(false)}
                                    options={options}
                                    size="small"
                                    groupBy={(option) => option.groupName}
                                    getOptionLabel={(option) => `${option.label}`}
                                    sx={{width: "100%"}}
                                    disableCloseOnSelect
                                    limitTags={2}
                                    isOptionEqualToValue={isOptionEqualToValue}
                                    renderOption={(props, option, {selected}) => (
                                        <li {...props} key={option.id}
                                            style={{
                                                cursor: option.disabled ? 'not-allowed' : 'pointer',
                                                pointerEvents: option.disabled ? 'none' : 'auto',
                                            }}
                                        >
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{marginRight: 8}}
                                                checked={selected || option.included}
                                                disabled={option.disabled}
                                            />
                                            <Tooltip title={option.tooltip}>
                                                <div>{option.label}</div>
                                            </Tooltip>
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                                   label={t("creativeDetails.categoriesPreview.input-label")}
                                                   placeholder={t("creativeDetails.categoriesPreview.input-placeholder")}
                                                   disabled={isSearchDisabled}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                {
                    isEditMode ? (
                        <Box sx={{display: 'flex', p: '16px', alignItems: 'center', gap: 1}}>
                            <Button onClick={resetEditor} variant="outlined" size="medium">
                                Cancel
                            </Button>
                            <Button onClick={submitCategoriesChanges} variant="contained" size="medium">
                                Save Changes
                            </Button>
                        </Box>
                    ) : null
                }
            </Card>
        </Box>
            )}
        </>
    )
};

export default IABCategoriesPreview;
