import React from 'react';
import impressionsImg from '../../../../assets/images/cards/impressions-card-image.png';
import revenueImg from '../../../../assets/images/cards/revenue-card-image.png';
import {Button, Typography, Box, Grid, Card, CardContent, CardMedia, CardActions} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {IDealSummaryResponse} from "../../../../typings/api-models/deal.models";
import {toCurrency} from "../../../../utils/formatter";
import {ReportingTabType} from "../enum/reportingTabType";


const LargeCardsContainer = ({dealsSummary, tabChange, setUnmounting}: { dealsSummary: IDealSummaryResponse,
    tabChange :(tab:number)=>void, setUnmounting: any}) => {

    const showDealPerformance = (event:React.MouseEvent<HTMLElement>) => {
        event.preventDefault()  ;
        setUnmounting(false);
        tabChange(ReportingTabType.DealPerformance);
    };

    const cardWithRightImage = (imgUrl: string, label: string, valueAsString: string) => (
        <Card sx={{display: 'flex', height: '158px'}}>
            <Box sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start'
            }}>
                <CardContent sx={{flex: '0 0 auto', p: '24px'}}>
                    <Typography component="div" variant="h5">
                        {valueAsString}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {label}
                    </Typography>
                </CardContent>
            </Box>
            <Box sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CardMedia
                    component="img"
                    sx={{width: 'auto', height: '104px'}}
                    image={imgUrl}
                    alt="Graphs and people drawing"
                />
            </Box>
        </Card>
    );


    return (
        <Box sx={{flexGrow: 1, mb: 3}}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Box sx={{pr: '8px'}}>
                        <Card sx={{display: 'flex', height: '158px'}}>
                            <Box sx={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
                                <CardContent sx={{flex: '0 0 auto', p: '16px 24px'}}>
                                    <Typography component="div" variant="h5">
                                        {dealsSummary.deal_count.toLocaleString()}
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" component="div">
                                        Active Deals
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{
                                    flex: '1 0 auto',
                                    width: '100%',
                                    height: '62px',
                                    boxShadow: 'inset 0px 1px 0px #E2E3E4'
                                }}>
                                    <Box sx={{display: 'flex', alignItems: 'center', pl: 1, pb: 1}}>
                                        <Button onClick={showDealPerformance} endIcon={<ArrowForwardIcon/>}>
                                            Check Deals
                                        </Button>
                                    </Box>
                                </CardActions>
                            </Box>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{pr: '8px'}}>
                        {cardWithRightImage(impressionsImg, 'Impressions', dealsSummary.played_impressions.toLocaleString())}
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    {cardWithRightImage(revenueImg, 'Total revenue', toCurrency(dealsSummary.played_revenue).toString())}
                </Grid>
            </Grid>
        </Box>
    )
};

export default LargeCardsContainer;

