import React, {useEffect, useState} from 'react';
import {Alert, AlertTitle, Box, Button} from '@mui/material';
import {PageTitle} from "../../common/pageTitle";
import {useTranslation} from "react-i18next";
import {setTabTitle} from "../../common/tabTitle";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import CreativeDetailsTabs from "./components/CreativeDetailsTabs";
import {PageHeaderChipProps} from "../../../typings/common.types";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import {WatchLater} from "@mui/icons-material";
import {useMutation} from 'react-query';
import CreativesListService from "../../../utils/services/CreativesListService";
import toast from "react-hot-toast";
import {useAuth0} from "@auth0/auth0-react";
import {Creative, CreativeStatusUpdateParams, Status} from "./models/CreativesList";
import Toaster from "../../common/Toaster/Toaster";
import ApproveModal from "./components/ApproveModal/ApproveModal";
import RejectModal from "./components/RejectModal/RejectModal";
import {CustomAlert} from "../../common/CustomAlert";
import {capitalizeFirstLetter} from "../../common/CapitalLetterConvertor";
import LoadingIndicator from "../../common/LoadingIndicator";
import {AxiosResponse} from "axios";
import {iLdskHttpError} from "../../../typings/api-models/commonApi.models";
import NoData from "../../common/NoData";

import NetworkErrorMessages from "../../../utils/networkErrorMessages";;

const CreativeDetails = () => {
    const [t, i18n] = useTranslation('locale');
    const [creative, setCreative] = useState({} as Creative);
    const [status, setStatus] = useState("");
    const {creativeId} = useParams<{ creativeId?: string }>();
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const useNetworkErrorMessage = NetworkErrorMessages();

    useEffect(() => {
        toast.remove();
    }, [location.pathname]);

    const updateCreativeData = (updatedCreative: Creative) => {
        setStatus(updatedCreative.status);
        setCreative(updatedCreative);
    };

    const creativeStatus = capitalizeFirstLetter(status);

    type Colors = "success" | "error" | "warning" | undefined;

    let chipColor: Colors, chipIcon, isApproveDisabled, isRejectDisabled;

    switch (creativeStatus) {
        case 'Approved': {
            chipColor = "success";
            chipIcon = <Check/>;
            isApproveDisabled = true;
            break;
        }
        case 'Rejected': {
            chipColor = "error";
            chipIcon = <Close/>;
            isRejectDisabled = true;
            break;
        }
        case 'Pending': {
            chipColor = "warning";
            chipIcon = <WatchLater/>;
            isApproveDisabled = false;
            isRejectDisabled = false;
            break;
        }
    }

    const pageHeaderChip: PageHeaderChipProps = {
        label: creativeStatus,
        color: chipColor,
        icon: chipIcon,
        size: "small"
    };
    useEffect(() => {
        return () => {
            toast.remove(); // Remove any toasts when the component unmounts
        };
    }, []);

    const goToCreativeApproval = () => {
        navigate('/creative-approval')
    };

    const [currentNoDataProps, setCurrentNoDataProps] = useState(useNetworkErrorMessage);
    const [shouldShowNoData, setShouldShowNoData] = useState(false)

    setTabTitle(t('creativeDetails.tabTitle'));

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            await CreativesListService.getById(creativeId)
                .then((response: AxiosResponse) => {
                    if (!response) {
                        throw new Error('Network error occurred.');
                    }
                    const {data} = response;
                    updateCreativeData(data);
                    setIsLoading(false);
                })
                .catch((errorResponse: iLdskHttpError) => {
                    setIsLoading(false);
                    if (errorResponse.message.includes('Network error occurred.')) {
                        setCurrentNoDataProps(useNetworkErrorMessage);
                        setShouldShowNoData(true);
                        toast.error(t('network-error.toast-message'));
                    } else {
                        toast.error(t('creatives.notifications.list-load.errors.generic'));
                    }
                });
        })()
    }, []);

    const mutation = useMutation(
        async ({creativeId, status, rejectReason}: CreativeStatusUpdateParams) => {

            return CreativesListService.updateStatus(creativeId, status, rejectReason);
        },
        {
            onError: (error, variables) => {
                const action =
                    variables.status === Status.REJECTED ? 'rejection' : 'approval';

                toast.error(t('creatives.notifications.status-change.errors' + action));
            },
            onSuccess: (response, {status, creativeId, rejectReason}) => {
                const actionResult = status === Status.REJECTED ? 'rejected' : 'approved';

                // Since only the status has changed, update that on the current creative to avoid a lookup call
                let updatedCreative = creative;
                updatedCreative.status = status;
                updatedCreative.rejection_reason = rejectReason ?? "";

                updateCreativeData(updatedCreative);
                toast.remove();
                toast.custom(
                    <Alert severity="info">
                        <AlertTitle>{t('creatives.notifications.status-change.title')}</AlertTitle>
                        {t('creatives.notifications.status-change.body')}
                        {" "}
                        <strong>{t('creatives.notifications.status-change.status.' + actionResult)}</strong>.
                    </Alert>
                );
            }
        }
    );

    const handleCreativeRejection = (creativeId: string, reason?: string) => {
        mutation.mutate({creativeId, status: Status.REJECTED, rejectReason: reason});
    };

    const handleCreativeApproval = (creativeId: string) => {
        mutation.mutate({creativeId, status: Status.APPROVED});
    };

    const creativeActions = (
        <>
            <Box className="page-title-actions">
                <ApproveModal
                    creative={creative}
                    handleCreativeApproval={handleCreativeApproval}
                    type={'button'}
                />
                <RejectModal
                    creative={creative}
                    handleCreativeRejection={handleCreativeRejection}
                    type={'button'}
                />
            </Box>
        </>
    );
    return (
        <>
            {isLoading ? (
                <>
                    <PageTitle pageTitle={t('creativeDetails.title') + " " + creativeId}></PageTitle>
                            <LoadingIndicator/>
                </>
            ) : (
                <Box style={{gap: "16px"}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Button onClick={goToCreativeApproval} startIcon={<ArrowBackIcon/>}>
                            {t('creatives.title')}
                        </Button>
                    </Box>
                    <PageTitle key={creativeId} pageTitle={t('creativeDetails.title') + " " + (creative.adid ?? '')}
                               pageTitleActions={creativeActions} headerChip={pageHeaderChip}
                               customBreadcrumbs={t("bread-crumbs.creative-approval-creative-details")}
                               customBreadcrumbFunctionality={{onClicked: goToCreativeApproval}}></PageTitle>
                    {
                        (creative?.status == Status.REJECTED && creative.rejection_reason?.length > 0) ?
                            (
                                <Box sx={{width: 1}}>
                                    <CustomAlert severity={"error"} title={t('creatives.rejection-reason.title')}
                                                 message={creative.rejection_reason}></CustomAlert>
                                </Box>
                            ) : null
                    }
                    {
                        Object.keys(creative).length > 0 ?
                            (
                                <CreativeDetailsTabs creative={creative} updateCreative={updateCreativeData}/>
                            ) :
                            shouldShowNoData ?
                                <NoData showIcon={"event_busy"} title={currentNoDataProps.title}
                                        description={t(currentNoDataProps.description)}/> : null
                    }
                    <Toaster/>
                </Box>
            )}
        </>
    )
};

export default CreativeDetails;

