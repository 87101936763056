import React, {useState} from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    OutlinedInput,
    Select,
    SelectChangeEvent
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useTranslation} from "react-i18next";

const MultipleSelectFilter = ({
                                  identifier, placeholder, values, selectedValues, setSelectedValues, isAllSelectionAllowed,
                                  isEmptyStateAllowed, setUnmounting
                              }: {
    identifier: string,
    placeholder: string,
    values: Map<string, string>,
    selectedValues: string[],
    setSelectedValues: any,
    isAllSelectionAllowed: boolean,
    isEmptyStateAllowed: boolean,
    setUnmounting: any
}) => {

    const [t, i18n] = useTranslation('locale');
    const [localSelectedValues, setLocalSelectedValues] = React.useState<string[]>([]);
    const DEFAULT_VALUE = "0";
    const DEFAULT_VALUE_LABEL = t("filter.all");

    const handleChange = (event: SelectChangeEvent<typeof localSelectedValues>) => {
        const {
            target: {value},
        } = event;

        let selected = typeof value === 'string' ? value.split(',') : value;

        if (!isEmptyStateAllowed && selected.length == 0) {
            return;
        }

        if (isAllSelectionAllowed && selected.length > 0) {
            let lastSelectedValue = selected[selected.length - 1];
            if (lastSelectedValue == DEFAULT_VALUE) {
                selected = [DEFAULT_VALUE, ...Array.from(values.keys())];
            } else {
                selected = selected.filter(x => x != DEFAULT_VALUE);
                if (selected.length == values.size) {
                    selected = [DEFAULT_VALUE, ...Array.from(values.keys())];
                }
            }
        }

        handleSetLocalSelectedValues(selected);

        if (isAllSelectionAllowed) {
            selected = selected.includes(DEFAULT_VALUE) ? [] : selected;
        }
        setSelectedValues(selected);
    }

    const handleClose = () => {
        setLocalSelectedValues([]);
        setUnmounting(false);
    }
    
    const handleOpen = () => {
        if (isAllSelectionAllowed && selectedValues.length == values.size) {
            selectedValues = [DEFAULT_VALUE, ...Array.from(values.keys())];
        }
        handleSetLocalSelectedValues(selectedValues);
    }

    const handleSetLocalSelectedValues = ((selectedValues:string[]) =>{
        if (isAllSelectionAllowed && selectedValues.length == 0) {
            selectedValues = [DEFAULT_VALUE, ...Array.from(values.keys())];
        }
        setLocalSelectedValues(selectedValues);
        setUnmounting(false);
    })

    return (
        <Box
            sx={{
                maxWidth: '100%',
            }}
        >
            <FormControl sx={{ width: '100%'}} size="small">
                <InputLabel id="multiple-checkbox-label">{placeholder}</InputLabel>
                <Select
                    labelId={`${identifier}-multiple-checkbox-label`}
                    id={`${identifier}-multiple-checkbox`}
                    multiple
                    value={localSelectedValues}
                    onChange={handleChange}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    input={<OutlinedInput label={placeholder} />}
                    renderValue={(selected) =>{placeholder}}
                    // open={true}
                >
                    {isAllSelectionAllowed && (
                        <MenuItem key={DEFAULT_VALUE} value={DEFAULT_VALUE}>
                            <Checkbox checked={localSelectedValues.indexOf(DEFAULT_VALUE) > -1}/>
                            <ListItemText primary={DEFAULT_VALUE_LABEL}/>
                        </MenuItem>
                    )}

                    {Array.from(values).map(([key, label]) => (
                        <MenuItem key={key} value={key}>
                            <Checkbox checked={localSelectedValues.indexOf(key) > -1}/>
                            <ListItemText primary={label}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )
};

export default React.memo(MultipleSelectFilter);