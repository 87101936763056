// MUI components imports
import { Box, Typography } from "@mui/material";
import logo from "../../../assets/images/logos/logo-primary.png";

export const Footer = () => {

    return (
            <Box
                id='footer'
                color="inherit"
                className="footer"
            >
                <div>
                    <Typography color="text.secondary" className={'footer-typography'}>
                        powered by
                        <img alt={'LDSK logo'} className="poweredByLogo" src={logo} />
                    </Typography>
                </div>
            </Box>
    )
}