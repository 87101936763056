import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import {Typography} from "@mui/material";
import theme from "../../theme/theme";

const Wrapper = styled('div')(({ theme }) => ({
	width: '84vw',
	height: '65vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: theme.palette.background.paper,
	flexDirection: 'column'
}));

const LoadingIndicator = () => (
	<Wrapper data-testid='loading-indicator'>
		<CircularProgress />
		<Typography>Please wait a moment...</Typography>
	</Wrapper>
);

export default LoadingIndicator;
