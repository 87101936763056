// React //
import React, {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {useMutation, useQuery} from 'react-query';
import {useLocation} from 'react-router-dom';

// Lumina Components //
import {PageTitle} from '../../common/pageTitle';
import {setTabTitle} from '../../common/tabTitle';
import SearchFilter from "../../common/Toolbar/SearchFilter";

// Auth0 //
import {useAuth0} from '@auth0/auth0-react';

// Material UI //
import {
    Alert,
    AlertTitle,
    Box,
    Card,
    FormControl,
    Grid,
    IconButton,
    Select,
    SelectChangeEvent,
    Tooltip
} from '@mui/material';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import {
    DataGridPremium,
    GridColDef,
    GridPaginationModel,
    GridSortModel,
    GridToolbarContainer,
    LicenseInfo
} from '@mui/x-data-grid-premium';

// CreativeList //
import {Creative, IABCategory, Status} from './models/CreativesList';
import {creativeStatusMap, dealTypesMap, mimeTypes, mimeTypesMap} from '../../../utils/formatter';
import CreativesListService from "../../../utils/services/CreativesListService";
import {GetAllCreativesParams} from '../../../utils/services/CreativesListService';

import PreviewModal from './components';
import {WatchLater} from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import NoData from "../../common/NoData";
import ApproveModal from "./components/ApproveModal/ApproveModal";
import RejectModal from "./components/RejectModal/RejectModal";
import MenuItem from "@mui/material/MenuItem";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import MultipleSelectFilter from "../../common/Toolbar/MultipleSelectFilter";
import dayjs from 'dayjs';
import FilterChip from "../../common/Toolbar/FilterChip";
import {NoRowsOverlayPropsOverrides} from "@mui/x-data-grid";
import Toaster from "../../common/Toaster/Toaster";
import CopyToClipboard from "../../common/CopyToClipboard/CopyToClipboard";
import CopyToClipboardSnackbar from "../../common/CopyToClipboard/CopyToClipboardSnackbar";
import {SnackbarOrigin} from "@mui/material/Snackbar";
import {subtractFixedWidth} from "../../../utils/responsivePages";

import NetworkErrorMessages from "../../../utils/networkErrorMessages";;
import {capitalizeFirstLetter} from "../../common/CapitalLetterConvertor";
import {useTranslation} from "react-i18next";

export interface SnackbarState extends SnackbarOrigin {
    open: boolean;
}

LicenseInfo.setLicenseKey("c46ad844731082bade1916933595065dTz01ODUxMCxFPTE3MDYzNTI4Nzc3OTEsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

interface MutationParams {
    creativeId: string;
    status: Status.REJECTED | Status.APPROVED;
    rejectReason?: string;
}

type CustomColDef = GridColDef & { hide?: boolean };

const DEAFULT_PAGE_SIZE = 10;
const DEFAULT_SORT_FIELD = '2';
const DEFAULT_SORT_DIRECTION = 'desc';
const VISIBLE_CATEGORIES_COUNT = 2;

const CreativesList = () => {
    const [t, i18n] = useTranslation('locale');
    const location = useLocation();
    setTabTitle(t('creatives.tabTitle'));
    const useNetworkErrorMessage = NetworkErrorMessages();

    const [creatives, setCreatives] = useState<Creative[]>([]);
    const [totalCreatives, setTotalCreatives] = useState(0);
    const [statusFilter, setStatusFilter] = useState<Status[]>(Array.from(creativeStatusMap.keys()));
    const [typeFilter, setTypeFilter] = useState<string[]>(Array.from(mimeTypesMap.keys()));
    const [currentSortingField, setSortingField] = useState(DEFAULT_SORT_FIELD);
    const [currentSortingDirection, setSortingDirection] = useState(DEFAULT_SORT_DIRECTION);
    const [searchText, setSearchText] = useState('');
    const [filterDate, setFilterDate] = useState<Date | null>(null);
    const [filterDateValue, setFilterDateValue] = useState<Date | null>(null);
    const [snackbarState, setSnackbarState] = useState<SnackbarState>({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    });

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: DEAFULT_PAGE_SIZE,
    });
    const handleDateChange = (date: Date | null) => {
        setFilterDateValue(date);
        if (isValidDate(date)) {
            setFilterDate(date);
        }
        setUnmounting(false);
    };

    const isValidDate = (date: Date | null) => {
        if (date == null) {
            return true;
        }
        const currentDate = new Date();
        const millenniumStart = new Date(2000, 0, 1);

        return date <= currentDate && date >= millenniumStart;
    };

    const handleTypeFilterDelete = (value: string) => {
        let mimeTypeFilter = typeFilter.filter((filter) => filter !== value);
        if (mimeTypeFilter.length == 0) {
            mimeTypeFilter = Array.from(mimeTypesMap.keys());
        }
        setTypeFilter(mimeTypeFilter);
        setUnmounting(false);
    };

    const handleStatusFilterDelete = (value: Status) => {
        let statuses = statusFilter.filter((filter) => filter !== value);
        if (statuses.length == 0) {
            statuses = Array.from(creativeStatusMap.keys());
        }
        setStatusFilter(statuses);
        setUnmounting(false);
    };

    const handleDateFilterDelete = () => {
        setFilterDateValue(null);
        setFilterDate(null);
        setUnmounting(false);
    };

    const renderDateChips = () => {
        if (filterDate) {
            return (
                <FilterChip key='creative_filter_date' filterVariant={t('creatives.filters.calendar.placeholder')}
                            filterStatus={dayjs(filterDate).format('YYYY-MM-DD')}
                            onDelete={() => handleDateFilterDelete()}/>
            );
        }
        return null;
    }

    const renderStatusChips = () => {
        if (statusFilter.length == 0 || statusFilter.length == creativeStatusMap.size) {
            return null;
        }

        return (
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 1}}>
                {statusFilter.map((filter) => (
                    <FilterChip key={`creative_filter_status_${filter}`}
                                filterVariant={t('creatives.filters.status.placeholder')}
                                filterStatus={creativeStatusMap.get(filter) ?? ""}
                                onDelete={() => handleStatusFilterDelete(filter)}/>
                ))}
            </Box>
        );
    };

    const renderTypeChips = () => {
        if (typeFilter.length == 0 || typeFilter.length == mimeTypesMap.size) {
            return null;
        }

        return (
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 2}}>
                {typeFilter.map((filter) => (
                    <FilterChip key={`creative_filter_mime_type_${filter}`}
                                filterVariant={t('creatives.filters.mimeType.placeholder')}
                                filterStatus={mimeTypes[filter]} onDelete={() => handleTypeFilterDelete(filter)}/>
                ))}
            </Box>
        );
    };

    const noDataFromSearchProps: NoRowsOverlayPropsOverrides = {
        showIcon: "search_off",
        title: t('creatives.list-load.empty-search.title'),
        description: t('creatives.list-load.empty-search.sub-title')
    };

    const noDataFromFilterProps: NoRowsOverlayPropsOverrides = {
        showIcon: "filter_list_off",
        title: t('creatives.list-load.empty-filter.title'),
        description: t('creatives.list-load.empty-filter.sub-title')
    };
    const [currentNoDataProps, setCurrentNoDataProps] = useState(noDataFromSearchProps);

    const [isInputSearchFocused, setIsInputSearchFocused] = useState(false);
    const [debouncedSearchText, setDebouncedSearchText] = useState('');
    const CustomCreativesToolbar = () => {
        const statusChipsRender = renderStatusChips();
        const typeChipsRender = renderTypeChips();
        const dateChipsRender = renderDateChips();
        if (statusChipsRender || typeChipsRender || dateChipsRender)
            return (
                <>
                    <GridToolbarContainer className="grid-toolbar-chips" sx={{p: 2}}>
                        <Grid container spacing={2}>
                            {statusChipsRender && (
                                <Grid item>
                                    {statusChipsRender}
                                </Grid>
                            )}
                            {typeChipsRender && (
                                <Grid item>
                                    {typeChipsRender}
                                </Grid>
                            )}
                            {dateChipsRender && (
                                <Grid item>
                                    {dateChipsRender}
                                </Grid>
                            )}
                        </Grid>
                    </GridToolbarContainer>
                </>
            );
        return null;
    };

    const [isLoading, setIsLoading] = useState(false);
    const [unmounting, setUnmounting] = useState(false);
    const fetchCreativesData = async () => {
        try {
            toast.remove();
            setIsLoading(true);
            const params: GetAllCreativesParams = {
                page: paginationModel.page + 1,
                take: paginationModel.pageSize,
                sortby: currentSortingField,
                sortdirection: currentSortingDirection,
                search: searchText.trim(),
            };
            let currentNoDataPropsTemp = (statusFilter || creativeTypeFilter.length || filterDate) ? noDataFromFilterProps : noDataFromSearchProps;
            setCurrentNoDataProps(currentNoDataPropsTemp);

            if (statusFilter && statusFilter.length != creativeStatusMap.size) {
                params.status = statusFilter;
            }

            if (typeFilter.length && typeFilter.length != mimeTypesMap.size) {
                params.type = typeFilter;
            }

            if (filterDate) {
                params.createdDate = dayjs(filterDate).format('YYYY-MM-DD');
            }
            const response = await CreativesListService.getAll(params);
            if (!unmounting) {
                if (!response) {
                    throw new Error('Network error occurred.');
                }
                const {data} = response;
                setCreatives(data?.creatives || []);
                setTotalCreatives(
                    data?.count || (data?.creatives || []).length
                );
            }
        } catch (errorResponse) {
            if (!unmounting) {
                if (errorResponse.message.includes('Network error occurred.')) {
                    setCurrentNoDataProps(useNetworkErrorMessage);
                    toast.error(t('network-error.toast-message'));
                } else {
                    toast.error(t('creatives.notifications.list-load.errors.generic'));
                }
            }
        } finally {
            if (!unmounting) {
                setIsLoading(false);

            }
        }
    };

    useEffect(() => {

        fetchCreativesData();

        return () => {
            setUnmounting(true);
        };
    }, [paginationModel.page, paginationModel.pageSize, statusFilter, currentSortingField, currentSortingDirection,
        searchText, location, typeFilter,filterDate]);

    const handleStatusFilterChange = (status: Status | '') => {
        setStatusFilter(status);
    };

    const mutation = useMutation(
        async ({creativeId, status, rejectReason}: MutationParams) => {
            return CreativesListService.updateStatus(creativeId, status, rejectReason);
        },
        {
            onError: (error, variables) => {
                const action =
                    variables.status === Status.REJECTED ? 'rejection' : 'approval';

                toast.error(t('creatives.notifications.status-change.errors' + action));
            },
            onSuccess: (response, {status, creativeId}) => {
                const actionResult =
                    status === Status.REJECTED ? 'rejected' : 'approved';

                if (!statusFilter || statusFilter.includes(status)) {
                    setCreatives((prevState) =>
                        prevState.map((el: Creative) => ({
                            ...el,
                            status: el.id === creativeId ? status : el.status
                        }))
                    );
                } else {
                    fetchCreativesData();
                }
                toast.custom(
                    <Alert severity="info">
                        <AlertTitle>{t('creatives.notifications.status-change.title')}</AlertTitle>
                        {t('creatives.notifications.status-change.body')}
                        {" "}
                        <strong>{t('creatives.notifications.status-change.status.' + actionResult)}</strong>.
                    </Alert>
                );
            }
        }
    );

    const handleCreativeRejection = (creativeId: string, reason?: string) => {
        mutation.mutate({creativeId, status: Status.REJECTED, rejectReason: reason});
    };

    const handleCreativeApproval = (creativeId: string) => {
        mutation.mutate({creativeId, status: Status.APPROVED});
    };

    type CustomColDef = GridColDef & { hide?: boolean };

    const columns: CustomColDef[] = [
        {
            field: 'ad_id',
            headerName: 'Ad ID',
            sortable: false,
            minWidth: 200,
            flex: 0.2,
            renderCell: ({row, row: {adid}}) => (
                <CopyToClipboard id={'creatives-ad-id-' + row?.adid}
                                 valueToBeCloned={row?.adid}
                                 setSnackbarState={setSnackbarState}
                                 divClassName={'creatives-ad-id-name-info'}/>
            )
        },
        {
            field: '1',
            headerName: 'Advertiser',
            sortable: true,
            minWidth: 200,
            flex: 0.5,
            cellClassName: 'creative-advertiser',
            valueGetter: ({row}) => row.advertiser?.name || 'N/A'
        },
        {
            field: '0',
            headerName: 'DSP',
            sortable: false,
            minWidth: 260,
            cellClassName: 'creative-dsp',
            flex: 1,
            valueGetter: ({row}) => row.advertiser?.dsp_provider?.name || 'N/A'
        },
        {
            field: '3',
            headerName: 'Status',
            sortable: true,
            minWidth: 130,
            flex: 1,
            renderCell: ({row}) => {
                let icon, color;

                switch (row.status.toLowerCase()) {
                    case 'approved': {
                        icon = <Check/>;
                        color = 'success';
                        break;
                    }
                    case 'rejected': {
                        icon = <Close/>;
                        color = 'error';
                        break;
                    }
                    default: {
                        icon = <WatchLater/>;
                        color = 'warning';
                        break;
                    }
                }

                let formattedLabel = capitalizeFirstLetter(row.status.toLowerCase());

                return (<Chip
                    label={formattedLabel}
                    color={color}
                    icon={icon}
                    size="small"
                    className='creative-status-label'
                />);
            }
        },
        {
            field: 'deal_id',
            headerName: 'Deal ID',
            sortable: false,
            minWidth: 370,
            flex: 1,
            cellClassName: 'creative-deal-id',
            renderCell: ({row}) => (row?.dealid ? <CopyToClipboard id={'creatives-deal-id-' + row?.dealid}
                                                                   valueToBeCloned={row?.dealid}
                                                                   setSnackbarState={setSnackbarState}
                                                                   divClassName={'creatives-deal-id-name-info'}/> : 'N/A'),
        },
        {
            field: 'categories',
            headerName: 'Category',
            sortable: false,
            minWidth: 440,
            flex: 1,
            renderCell: ({row: {categories, id}}) => {
                if (!categories) return null;
                const remainingCount = categories.length - VISIBLE_CATEGORIES_COUNT;

                const visibleCategoriesChips = categories.slice(0, VISIBLE_CATEGORIES_COUNT).map(({
                                                                                                      code,
                                                                                                      name
                                                                                                  }: IABCategory) => {
                    const label = `${code} ${name}`;
                    const key = `${code}${id}`;
                    return (<Chip size="small" className="CategoryChip" key={key} label={label} sx={{mr: 1}}/>);
                });

                if (remainingCount > 0) {
                    const remainingLabel = `+${remainingCount}`;
                    const key = `${remainingCount}${id}`;
                    const hiddenCategories = categories.slice(VISIBLE_CATEGORIES_COUNT).map(({
                                                                                                 code,
                                                                                                 name
                                                                                             }: IABCategory) => `${code} ${name}`).join('\n');
                    const hiddenCategoriesTooltiprChip = (
                        <Tooltip key={key + "tooltip"}
                                 title={<Box sx={{whiteSpace: 'pre-line'}}>{hiddenCategories}</Box>} placement="bottom">
                            <Chip size="small" key={key} label={remainingLabel} sx={{cursor: 'pointer'}}/>
                        </Tooltip>
                    );
                    return [...visibleCategoriesChips, hiddenCategoriesTooltiprChip];
                }

                return visibleCategoriesChips;
            }
        },
        {
            field: 'mime_type',
            headerName: 'Type',
            minWidth: 120,
            flex: 0.6,
            sortable: false,
            cellClassName: 'creative-type',
        },
        {
            field: 'size',
            headerName: 'Size',
            sortable: false,
            minWidth: 130,
            flex: 0.6,
            valueGetter: ({row}) => {
                return `${row.dimensions.width} x ${row.dimensions.height}`;
            },
        },
        {
            field: '2',
            headerName: 'Created on',
            sortable: true,
            minWidth: 150,
            flex: 1,
            cellClassName: 'creative-creation-date',
            valueGetter: ({row}) => String(row.create_date).split('T')[0]
        },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 148,
            renderCell: ({row, row: {status, id}}) => (
                <Box>
                    <ApproveModal
                        creative={row}
                        handleCreativeApproval={handleCreativeApproval}
                        type={'iconButton'}
                    ></ApproveModal>
                    <RejectModal
                        creative={row}
                        handleCreativeRejection={handleCreativeRejection}
                        type={'iconButton'}
                    ></RejectModal>
                    <PreviewModal
                        creative={row}
                        data-testid='creatives-list-preview-button'
                    />
                </Box>
            )
        }
    ];

    const handlePaginationModelChange = (newModel: GridPaginationModel) => {
        setPaginationModel(newModel);
        setUnmounting(false);
    };

    const getGridHeight = () => {
        //if there are deals have auto height
        if (creatives.length > 0) {
            return "auto";
        }
        //if some filter is selected
        if (statusFilter.length > 0 || typeFilter.length > 0 || filterDate != null) {
            return 400;
        }
        return 350;
    }

    const handleSortModelChange = (sortModel: GridSortModel) => {
        // Every third click, it resets the sorting of the column to its default
        if (sortModel.length !== 0) {
            setSortingField(sortModel[0].field);
            setSortingDirection(sortModel[0].sort || 'asc');
        } else {
            setSortingField(DEFAULT_SORT_FIELD);
            setSortingDirection(DEFAULT_SORT_DIRECTION);
        }
        setUnmounting(false);
    };

    const handleFilterSelectChange = (event: SelectChangeEvent) => {
        setStatusFilter(event.target.value);
    };

    return (
        <Box>
            <PageTitle pageTitle={t('creatives.title')}
                       customBreadcrumbs={t("bread-crumbs.creative-approval")}></PageTitle>
            <Card sx={{border: "none", boxShadow: "none"}}>
                <Box className="table-card-header">
                    <Box className="table-content">
                        <Grid container className="grid-toolbar" sx={{p: 2}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <SearchFilter placeholder={t("creatives.searchCreativesPlaceholder")}
                                                  searchText={searchText}
                                                  setSearchText={setSearchText}
                                                  debouncedSearchText={debouncedSearchText}
                                                  setDebouncedSearchText={setDebouncedSearchText}
                                                  isInputFocused={isInputSearchFocused}
                                                  setInputFocused={setIsInputSearchFocused}
                                                  setUnmounting={setUnmounting}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <MultipleSelectFilter identifier="creatives-status-filter-select"
                                                                  placeholder={t("creatives.filters.status.placeholder")}
                                                                  values={creativeStatusMap}
                                                                  selectedValues={statusFilter}
                                                                  setSelectedValues={setStatusFilter}
                                                                  isAllSelectionAllowed={false}
                                                                  isEmptyStateAllowed={false}
                                                                  setUnmounting={setUnmounting}/>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <MultipleSelectFilter identifier="creatives-mime-type"
                                                                  placeholder={t("creatives.filters.mimeType.placeholder")}
                                                                  values={mimeTypesMap}
                                                                  selectedValues={typeFilter}
                                                                  setSelectedValues={setTypeFilter}
                                                                  isAllSelectionAllowed={false}
                                                                  isEmptyStateAllowed={false}
                                                                  setUnmounting={setUnmounting}/>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl sx={{width: '100%'}} size="small">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                        label={t('creatives.filters.calendar.placeholder')}
                                                        value={filterDateValue}
                                                        onChange={handleDateChange}
                                                        format="dd/MM/yyyy"
                                                        disableFuture
                                                        slotProps={{textField: {size: 'small'}}}
                                                        minDate={new Date('2000-01-01')} // Set the minimum year to 1900
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div style={{height: getGridHeight(), width: `calc(100vw - ${subtractFixedWidth()}px)`}}>
                            <DataGridPremium
                                autoHeight={creatives.length != 0}
                                disableColumnResize={true}
                                disableColumnReorder={true}
                                disableColumnMenu={true}
                                disableRowSelectionOnClick={true}
                                rowHeight={52}
                                rows={creatives}
                                rowCount={totalCreatives}
                                getRowId={(row: any) => row.id}
                                columns={columns}
                                pagination
                                className="grid-data"
                                loading={isLoading}
                                pageSizeOptions={[10, 25, 50, 100]}
                                paginationMode='server'
                                paginationModel={paginationModel}
                                onPaginationModelChange={handlePaginationModelChange}
                                sortingOrder={['asc', 'desc']}
                                sortingMode="server"
                                onSortModelChange={handleSortModelChange}
                                initialState={{
                                    sorting: {
                                        sortModel: [{field: DEFAULT_SORT_FIELD, sort: DEFAULT_SORT_DIRECTION}],
                                    },
                                    pinnedColumns: {right: ['actions']}
                                }}
                                slots={{
                                    toolbar: CustomCreativesToolbar,
                                    noRowsOverlay: NoData,
                                    noResultsOverlay: NoData
                                }}
                                slotProps={{
                                    toolbar: {},
                                    noRowsOverlay: currentNoDataProps,
                                    noResultsOverlay: currentNoDataProps
                                }}
                            />
                        </div>
                    </Box>
                </Box>
            </Card>
            <Toaster/>
            <CopyToClipboardSnackbar setSnackbarState={setSnackbarState} snackbarState={snackbarState}/>
        </Box>
    );
};

export default CreativesList;
