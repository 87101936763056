import React from 'react';
import {Box, IconButton, Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";
import {SnackbarOrigin} from "@mui/material/Snackbar";
import toast from "react-hot-toast";
import {CustomAlert} from "../../common/CustomAlert";
import Typography from "@mui/material/Typography";

const CopyToClipboard = ({
                             id,
                             valueToBeCloned,
                             setSnackbarState,
                             divClassName
                         }: { id: string, valueToBeCloned?: string, setSnackbarState: any, divClassName: string }) => {

    const [t, i18n] = useTranslation('locale');

    const showCopyIcon = (e: any) => {
        e.currentTarget.lastChild.style.display = 'flex';
    };

    const hideCopyIcon = (e: any) => {
        e.currentTarget.lastChild.style.display = 'none';
    };

    const handleCopyToClipboard = (e: any, newState: SnackbarOrigin, valueToBeCloned?: string) => {
        e.stopPropagation();

        if (valueToBeCloned) {

            navigator.clipboard.writeText(valueToBeCloned).then(() => {
                setSnackbarState({open: true, ...newState});
            }, () => {
                toast.custom(
                    <CustomAlert severity={"error"} title={t('copy-to-clipboard.copy-tooltip-title-failed')}
                                 message={t('copy-to-clipboard.copy-failed-message')}/>
                );
            });
        }
    };

    return (
        <Box id={id}
             sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer'}}
             onMouseEnter={e => showCopyIcon(e)} onMouseLeave={e => hideCopyIcon(e)}>
            <Typography component="div" className={divClassName}>{valueToBeCloned}</Typography>
            <Tooltip title={t('copy-to-clipboard.copy-tooltip-title')} sx={{display: 'none', padding: 0}}>
                <IconButton aria-label="copy"
                            onClick={e => handleCopyToClipboard(e, {
                                vertical: 'top',
                                horizontal: 'right'
                            }, valueToBeCloned)}>
                    <span className="material-symbols-rounded size-24">content_copy</span>
                </IconButton>
            </Tooltip>
        </Box>
    )
};

export default CopyToClipboard;


