import {createTheme} from '@mui/material/styles';
import '../styles/theme/_variables.scss';
import styles from '../styles/theme/style.module.scss';
import {Color, CommonColors, TypeText, TypeBackground, TypeOther, Components} from '@mui/material/styles/createPalette';
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import {within} from "@testing-library/react";

const theme = createTheme({
    palette: {
        primary: {
            main: styles.colorPrimaryMain,
            light: styles.colorPrimaryLight,
            dark: styles.colorPrimaryDark,
            contrastText: styles.colorPrimaryContrast,
        },
        secondary: {
            main: styles.colorSecondaryMain,
            light: styles.colorSecondaryLight,
            dark: styles.colorSecondaryDark,
            contrastText: styles.colorSecondaryContrast
        },
        tertiary: {
            main: styles.colorTertiaryMain,
            light: styles.colorTertiaryLight,
            dark: styles.colorTertiaryDark,
            contrastText: styles.colorTertiaryContrast
        },
        success: {
            main: styles.colorSuccessMain,
            light: styles.colorSuccessLight,
            dark: styles.colorSuccessDark,
            contrastText: styles.colorSuccessContrast
        },
        info: {
            main: styles.colorInfoMain,
            light: styles.colorInfoLight,
            dark: styles.colorInfoDark,
            contrastText: styles.colorInfoContrast
        },
        warning: {
            main: styles.colorWarningMain,
            light: styles.colorWarningLight,
            dark: styles.colorWarningDark,
            contrastText: styles.colorWarningContrast
        },
        error: {
            main: styles.colorErrorMain,
            light: styles.colorErrorLight,
            dark: styles.colorErrorDark,
            contrastText: styles.colorErrorContrast
        },
        common: {
            white: styles.colorCommonWhite,
            black: styles.colorCommonBlack,
            slate: styles.colorCommonSlate
        },
        background: {
            default: styles.colorBackgroundDefault,
            paper: styles.colorBackgroundPaper,
            dark: styles.colorBackgroundDark,
            colorNeutral100: styles.colorNeutral100
        },
        action: {
            active: styles.colorActionActive,
            disabled: styles.colorActionDisabled,
            disabledBackground: styles.colorActionDisabledBackground,
            //disabledOpacity: 0.38, The opacity values are not necessary because the alpha values is defined in the scss variables
            focus: styles.colorActionFocused,
            //focusOpacity 0.12,
            hover: styles.colorActionHover,
            //hoverOpacity: 0.04,
            selected: styles.colorActionSelected,
            //selectedOpacity: 0.08
        },
        text: {
            primary: styles.colorTextPrimary,
            secondary: styles.colorTextSecondary,
            disabled: styles.colorTextDisabled,
            highlight: styles.colorTextHighlight,
            primaryInverse: styles.colorTextPrimaryInverse,
            secondaryInverse: styles.colorTextSecondaryInverse
        },
        divider: styles.colorOtherOutlinedBorder,
        other: {
            outlinedBorder: styles.colorOtherOutlinedBorder,
            backdropOverlay: styles.colorOtherBackdropOverlay,
            filledInputBackground: styles.colorOtherFilledInputBackground,
            standardInputLine: styles.colorOtherStandardInputLine,
            snackbar: styles.colorOtherSnackbar,
            ratingActive: styles.colorOtherRatingActive
        },
    },
    typography: {
        fontFamily: [
            'Roboto'
        ].join(','),
        fontWeightLight: 400,
        button: {
            textTransform: "none",
            fontWeight: 500,
        }
    },
    components: {
        MuiChip:{
            styleOverrides:{

            },
            variants: [
                {
                    props: { className: 'CategoryChip' },
                    style: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: 190,
                    },
                },
            ],
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    borderColor: styles.colorOtherDivider,
                    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
                        backgroundColor: styles.colorBackgroundPaper
                    },
                },
                cell: {
                    outline: "none !important",
                    columnHeader:{
                        outline: "none !important",
                    },
                    padding: '0 16px'
                },
                columnSeparator:{
                    display: "none"
                },
                withBorderColor: {
                    borderColor: styles.colorOtherDivider,
                },
                columnHeader: {
                  padding: '0 16px'
                },
                columnHeaderTitle: {
                    fontWeight: 800,
                },
                pinnedColumns: {
                    backgroundColor: styles.colorBackgroundPaper,
                },
                pinnedColumnHeaders: {
                    backgroundColor: styles.colorBackgroundPaper,
                },
                toolbarContainer: {
                    borderBottom: '1px solid ' + styles.colorNeutral100
                },
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                popper: {
                    minHeight: '360px',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '4px 8px',
                    background: styles.colorOtherStandardInputLine,
                    borderRadius: '4px',
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    width: 240,
                    backgroundColor: styles.colorPrimaryMain
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: ({ownerState}) => ({
                    /* Contained Button */
                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'primary' && {
                            "&:hover": {
                                backgroundColor: styles.colorPrimaryContainedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'secondary' && {
                            "&:hover": {
                                backgroundColor: styles.colorSecondaryContainedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'tertiary' && {
                            "&:hover": {
                                backgroundColor: styles.colorTertiaryContainedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'success' && {
                            "&:hover": {
                                backgroundColor: styles.colorSuccessContainedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'info' && {
                            "&:hover": {
                                backgroundColor: styles.colorInfoContainedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'warning' && {
                            "&:hover": {
                                backgroundColor: styles.colorWarningContainedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'error' && {
                            "&:hover": {
                                backgroundColor: styles.colorErrorContainedHoverBackground
                            }
                        }),
                    /* Outlined Button */
                    ...(ownerState.variant === 'outlined' &&
                        ownerState.color === 'primary' && {
                            "&:hover": {
                                backgroundColor: styles.colorPrimaryOutlinedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'outlined' &&
                        ownerState.color === 'secondary' && {
                            "&:hover": {
                                backgroundColor: styles.colorSecondaryOutlinedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'outlined' &&
                        ownerState.color === 'tertiary' && {
                            "&:hover": {
                                backgroundColor: styles.colorTertiaryOutlinedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'outlined' &&
                        ownerState.color === 'success' && {
                            "&:hover": {
                                backgroundColor: styles.colorSuccessOutlinedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'outlined' &&
                        ownerState.color === 'info' && {
                            "&:hover": {
                                backgroundColor: styles.colorInfoOutlinedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'outlined' &&
                        ownerState.color === 'warning' && {
                            "&:hover": {
                                backgroundColor: styles.colorWarningOutlinedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'outlined' &&
                        ownerState.color === 'error' && {
                            "&:hover": {
                                backgroundColor: styles.colorErrorOutlinedHoverBackground
                            }
                        }),
                    /* Text Button */
                    ...(ownerState.variant === 'text' &&
                        ownerState.color === 'primary' && {
                            "&:hover": {
                                backgroundColor: styles.colorPrimaryOutlinedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'text' &&
                        ownerState.color === 'secondary' && {
                            "&:hover": {
                                backgroundColor: styles.colorSecondaryOutlinedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'text' &&
                        ownerState.color === 'tertiary' && {
                            "&:hover": {
                                backgroundColor: styles.colorTertiaryOutlinedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'text' &&
                        ownerState.color === 'success' && {
                            "&:hover": {
                                backgroundColor: styles.colorSuccessOutlinedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'text' &&
                        ownerState.color === 'info' && {
                            "&:hover": {
                                backgroundColor: styles.colorInfoOutlinedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'text' &&
                        ownerState.color === 'warning' && {
                            "&:hover": {
                                backgroundColor: styles.colorWarningOutlinedHoverBackground
                            }
                        }),
                    ...(ownerState.variant === 'text' &&
                        ownerState.color === 'error' && {
                            "&:hover": {
                                backgroundColor: styles.colorErrorOutlinedHoverBackground
                            }
                        }),
                }),
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    borderRadius: "4px"
                },
            },
        },
        MuiStack: {
            defaultProps: {
                useFlexGap: true,
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: `0px 0px 0px 1px ` + styles.colorNeutral100,
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                action: {
                    alignSelf: 'center'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    span: {
                        color: styles.colorPrimaryMain,
                    },
                    '& .MuiChip-label': {
                        color: styles.colorTextPrimary,
                    },
                    '& .MuiBox-root': {
                        span: {
                            color: styles.colorActionActive,
                        }
                    }
                }
            }
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    marginTop: '50px',
                }
            }
        },

        MuiSnackbarContent: {
            styleOverrides: {
               root: {
                   '@media (min-width: 0px)': {
                       minWidth: 'auto'
                   },
               }
            }
        },

    }
});
export default theme;