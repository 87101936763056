import {AppState} from "@auth0/auth0-react";
import {createBrowserHistory} from "history";
import {singleton} from "tsyringe";
import {IAuthUser, IUserInfoResponse} from "../../typings/common.types";
import {AuthorizationType} from "../../typings/enums/authorizationType.enum";
import {LogVerbosityLevel} from "../../typings/enums/logVerbosityLevel.enum";
import {IAuthorizationService} from "./interfaces/iAuthorization.service";

@singleton()
export class AuthorizationService implements IAuthorizationService {
    // no loggerService because of circular dependency
    private readonly localStorageKey: string = 'classic-ui-user';
    private readonly apiUrl: string = process.env.AUTH_API_URL || '';

    getCurrentUser(): IAuthUser {
        const localStorageUser: string | null = localStorage.getItem(this.localStorageKey);
        if (!localStorageUser) {
            let currentUser = this.initDefaultAuthUser();
            return currentUser;
        }

        let currentUser = JSON.parse(localStorageUser);
        return currentUser;
    }

    getAccessToken(): string {
        const currentUser = this.getCurrentUser();
        return currentUser.accessToken;
    }

    onLoginRedirect = async (appState?: AppState): Promise<void> => {
        const authUser: IAuthUser = await this.deserilizeAuth0User();
        let userInfo: IUserInfoResponse | null = null;

        localStorage.setItem(this.localStorageKey, JSON.stringify(authUser));
        userInfo = await this.getUserDetailsViaHttpAsync(authUser);
        if (userInfo) {
            authUser.firstName = userInfo.firstName;
            authUser.lastName = userInfo.lastName;
            authUser.currencySymbol = userInfo.currencySymbol;
            authUser.roles = userInfo.roles;
            authUser.permissions = userInfo.permissions;
        }
        localStorage.setItem(this.localStorageKey, JSON.stringify(authUser));
        /*
          First localStorage.setItem is used to add token to the user in localStorage what is needed to proceed api calls
          Second one localStorage.setItem is user Details what are fetched from API like Name,LastName Symbol and that endpoint is not including token
         */
        //this.history.replace(appState?.returnTo || window.location.pathname);
    };


    removeUserFromLocalStorage(): void {
        localStorage.removeItem(this.localStorageKey);
    }

    getAuth0ClientId(): string {
        return process.env.REACT_APP_AUTH0_CLIENT_ID || '';
    }

    getAuth0Domain(): string {
        return process.env.REACT_APP_AUTH0_DOMAIN || '';
    }

    getAuth0Audience(): string {
        return process.env.REACT_APP_AUTH0_AUDIENCE || '';
    }


    /** deserilize token and validate for missing properties */
    private deserilizeAuth0User(): IAuthUser {
        let authUser: IAuthUser = this.initDefaultAuthUser();

        let auth0ClientId = this.getAuth0ClientId();
        for (const localStorageKey in localStorage) {
            if (localStorageKey.includes(auth0ClientId)) {
                const localStorageValue = localStorage.getItem(localStorageKey);
                if (localStorageValue) {
                    const localStorageObject = JSON.parse(localStorageValue);

                    authUser.accessToken = localStorageObject.body.access_token;
                    authUser.expiresAt = localStorageObject.expiresAt;
                    authUser.email = localStorageObject.body.decodedToken.user.email;
                    authUser.userId = localStorageObject.body.decodedToken.user.sub;
                    authUser.authorizationType = AuthorizationType.LivedoohCredentials;

                    const organizationId = localStorageObject.body.decodedToken.claims['http://local.livedooh.com/organization'];
                    const userGroups = localStorageObject.body.decodedToken.claims['http://local.livedooh.com/groups'];

                    authUser.organizationId = organizationId;
                    authUser.userGroups = userGroups;
                }
            }
        }

        return authUser;
    }

    private async getUserDetailsViaHttpAsync(authUser: IAuthUser): Promise<IUserInfoResponse> {
        let apiUrl: string;

        if (process.env.IDENTITY_API_VERSION == 'v1') {
            apiUrl = `${this.apiUrl}/users/${authUser.userId}/details`;
        } else {
            apiUrl = `${this.apiUrl}/users/v2/${authUser.userId}/user-info/${authUser.organizationId}`;
        }
        const userInfoResponse = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${authUser.accessToken}`
            }
        });

        return await userInfoResponse.json();
    }

    private initDefaultAuthUser(): IAuthUser {
        let authUser: IAuthUser = {
            accessToken: "",
            expiresAt: 0,
            email: "",
            userId: "",
            authorizationType: AuthorizationType.LivedoohCredentials,
            organizationId: 0,
            firstName: "",
            lastName: "",
            currencySymbol: "",
            userGroups: [],
            permissions: [],
            roles: []
        };

        return authUser;
    }
}