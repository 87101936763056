import {MimeType, Status} from "../modules/views/CreativesList/models/CreativesList";
import {DealStatus} from "../modules/views/Deals/models/Deals";
import {i18next} from "../index";


const currency_formatting_options = {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
}
const currencyFormatter = new Intl.NumberFormat('en-US', currency_formatting_options);

export const getCurrencyFormatter = (currency: string) => {
    const currency_settings = {
        style: 'currency',
        currency: currency ?? 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }
    return new Intl.NumberFormat('en-US', currency_settings);
}

const percentage_formatting_options = {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
};

const percentageFormatter = new Intl.NumberFormat("en-US", percentage_formatting_options);

export const toPercentage = (value: any) => {

    if (isNaN(value)) {
        return i18next.t("error.noData");
    }
    return percentageFormatter.format(value);
}
export const toCurrency = (value: any) => {

    if (isNaN(value)) {
        return i18next.t("error.noData");
    }
    return currencyFormatter.format(value);
}

export const dealTypes: dealTypeOptions = {
    '1': 'First Price',
    '2': 'Second Price',
    '3': 'Fixed Price'
}
export const dealTypesMap: Map<string, string> = new Map<string, string>([
    ['1', 'First Price'],
    ['2', 'Second Price'],
    ['3', 'Fixed Price']
]);

type mimeTypeOptions = {
    [key: string]: string;
}

export const mimeTypes: dealTypeOptions = {
    'image': 'Image',
    'video': 'Video',
    'html': 'HTML5'
}
export const mimeTypesMap: Map<string, string> = new Map<string, string>([
    ['image', 'Image'],
    ['video', 'Video'],
    ['html', 'HTML5']
]);

export const creativeStatusMap: Map<Status, string> = new Map<Status, string>([
    [Status.APPROVED, "Approved"],
    [Status.PENDING, "Pending"],
    [Status.REJECTED, "Rejected"]
]);

type dealTypeOptions = {
    [key: string]: string;
}

export const dealStatusMap: Map<DealStatus, string> = new Map<DealStatus, string>([
    [DealStatus.INACTIVE, "Inactive"],
    [DealStatus.ACTIVE, "Active"]
]);

export const numberFormatter = (value: any) => {
    if (isNaN(value)) {
        return i18next.t("error.noData");
    }
    return value;
}

