import React, {useState} from 'react';
import {Box, Typography} from '@mui/material';
// Lumina components
import {PageTitle} from "../../common/pageTitle";
import {useTranslation} from "react-i18next";
import {setTabTitle} from "../../common/tabTitle";
import ReportingTabs from "./components/ReportingTabs";

const Reporting = () => {
    const [t, i18n] = useTranslation('locale');

    function addDays(date: Date, days: number) {
        let newDate = new Date();
        newDate.setDate(date.getDate() + days);
        return newDate;
    }

    function subtractDays(date: Date, days: number) {
        let newDate = new Date();
        newDate.setDate(date.getDate() - days);
        return newDate;
    }


    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();

    const mapDateRange = (dateRangeValue: string) => {

        let today = new Date();

        let startDateTemp = null;
        let endDateTemp = null;

        switch (dateRangeValue) {
            case 'today':
                startDateTemp = today;
                endDateTemp = addDays(today, 1);
                break;
            case 'yesterday':
                startDateTemp = subtractDays(today, 1);
                endDateTemp = subtractDays(today, 1);
                break;
            case 'last_7_days':
                startDateTemp = subtractDays(today, 7);
                endDateTemp = today;
                break;
            case 'last_30_days':
                startDateTemp = subtractDays(today, 30);
                endDateTemp = today;
                break;
            default:
                break;
        }

        if(startDateTemp && endDateTemp){
            setStartDate(startDateTemp);
            setEndDate(endDateTemp);
        }
    };

    setTabTitle(t('reporting.tabTitle'));

    const greetingMessage = (
        <Typography color="text.secondary">
            {t('reporting.greeting')}
        </Typography>
    )

    return (
        <Box>
            <PageTitle pageTitle={t('reporting.title')} isBreadcrumbAvailable={false} greetingMessage={greetingMessage}></PageTitle>
            <ReportingTabs
                startDate={startDate}
                endDate={endDate}
                mapDateRange={mapDateRange}
            />
        </Box>
    )
};

export default Reporting;

