import {Box} from '@mui/material';
// Lumina components
import {PageTitle} from "../../common/pageTitle";
import {useTranslation} from "react-i18next";
import {setTabTitle} from "../../common/tabTitle";
import { PageHeaderButtonProps } from '../../../typings/common.types';
import { useNavigate } from 'react-router-dom';
import DealsGridView from './DealsGridView';

const Deals = () => {
    const [t, i18n] = useTranslation('locale');
    const navigate = useNavigate();

    const pageHeaderButtons : Array<PageHeaderButtonProps> = [{
        name: t("deals.pageTitleButtons.create-deal"),
        icon: 'add',
        color: 'primary',
        onClicked: () => handleChange()
    }];

    async function handleChange(){
        navigate("/deals/new");
    }

    setTabTitle(t('deals.tabTitle'));

    return (
        <Box>
            <PageTitle pageTitle={t('deals.title')} headerButtons={pageHeaderButtons}></PageTitle>
            <DealsGridView></DealsGridView>
        </Box>
    )
};

export default Deals;

