// Should come from context api
import { IUserAccountMenu } from "../../../../typings/common.types"
import { Divider } from "@mui/material"
import { CaretDown, CaretUp, SignOut } from 'phosphor-react'
// MUI Components imports
import { 
        Menu, 
        List, 
        ListItem,
        ListItemButton, 
        ListSubheader, 
        ListItemIcon, 
        ListItemText,
        Collapse,
        Typography
    } from "@mui/material"

import { useEffect, useState } from 'react';

import Fade from '@mui/material/Fade';
import nlFlag from '../../../../assets/images/Flags/Netherlands.png'
import ukFlag from '../../../../assets/images/Flags/UK.png'


// Locale imports
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react';
import { container } from "tsyringe";
import { IAuthorizationService } from "../../../../utils/services/interfaces/iAuthorization.service";
import { AuthorizationService } from "../../../../utils/services/authorization.service";
import { LoggerService } from "../../../../utils/services/loggerService";
import { LogVerbosityLevel } from "../../../../typings/enums/logVerbosityLevel.enum";
import { ILoggerService } from "../../../../utils/services/interfaces/iLoggerService";

// todo: use classes instead of export const and arrow functions
export const UserAccountMenu = ({name, email, userMenu, setUserMenu}: IUserAccountMenu) => {
    const authorizationService: IAuthorizationService = container.resolve(AuthorizationService);
    const loggerService: ILoggerService = container.resolve(LoggerService);
    
    const currentUser = authorizationService.getCurrentUser();

    const menuData = [
        {
            id: 1,
            title: `${currentUser.firstName} ${currentUser.lastName}`,
            handleEvent: 'handleClose',
            classes: 'classes.menuItem',
            disabled: true,
            property: 'fullName'
        },
        {
            id: 2,
            subtitle: currentUser.email,
            handleEvent: 'handleClose',
            classes: 'classes.menuItem',
            disabled: true,
            property: 'email'
        },
        {
            id: 3,
            divider: <Divider variant="fullWidth"/>
        },
        // {
        //     id: 4,
        //     subtitle2: "userDropDownMenu.settings"
        // },
        // {
        //     id: 5,
        //     text: "userDropDownMenu.selectedLang",
        //     handleEvent: 'handleClose',
        //     classes: 'classes.menuItem',
        //     disabled: false,
        //     sx: {mb:1.5},
        //     // icon: <img src={ukFlag} className="iconFlag"/>,
        //     expandMoreIcon: <CaretUp size={24}/>,
        //     expandLessIcon: <CaretDown size={24}/>,
        //     langButton: true,
        // },
        // {
        //     id: 7,
        //     divider: <Divider variant="fullWidth"/>
        // },
        {
            id: 8,
            text: "userDropDownMenu.signOut",
            handleEvent: 'handleClose',
            classes: 'classes.menuItem',
            disabled: false,
            icon: <SignOut size={20} color="#000" className="userMenuIcon"/>,
            logoutButton: true
        },
    ];

    const [t, i18n] = useTranslation('locale')
    const { logout } = useAuth0();

    const [langMenu, setLangMenu] = useState(false);
    const [currentLang, setCurrentLang] = useState(i18n.languages[0])
    const handleClick = () => {
        setLangMenu(!langMenu);
    };

    const handleLogout = () => { 
        loggerService.logMessage(`User about to log off`, LogVerbosityLevel.Information);
        authorizationService.removeUserFromLocalStorage();
        logout({ returnTo: window.location.origin });
    }

    const open = Boolean(userMenu);
    const handleClose = () => {
        setUserMenu(null);
    };

    // let currentLang = i18n.languages[0]
    const changeLang = ()=> {
        if (currentLang === 'nl') i18n.changeLanguage('en')
        if (currentLang === 'en') i18n.changeLanguage('nl')
        setCurrentLang(i18n.languages[0])
    }
    return(
        <Menu
            id="basic-menu"
            anchorEl={userMenu}
            open={open}
            onClose={handleClose}
            elevation={1}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            TransitionComponent={Fade}
        >
            <List 
                sx={
                    { 
                        width: 266, 
                        bgcolor: 'background.paper', 
                        fontWeight: "300",
                     }
                    }
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {/* Loops through the menu data array */}
                {/* todo: instead of loop use the items and render each */}
                {menuData.map(item=> {
                    if(item.property == 'fullName'){
                        item.title = name == null ? '' : name;
                    }
                    else if (item.property == 'email'){
                        item.subtitle = email == null ? '' : email;
                    }

                    return <div key={item.id}>
                        {/* Gets the first element of the user*/}
                        <ListItem sx={{paddingLeft: 3}}>
                            <Typography variant='subtitle2' fontWeight={700} mb={-2}>
                                {item.title}
                            </Typography>
                        </ListItem>
                        <ListSubheader component="div" id="nested-list-subheader" sx={{paddingLeft: 3}}>
                            {item.subtitle}
                        </ListSubheader>
                        <ListSubheader component="div" sx={{paddingLeft: 3, fontSize: '16px', fontWeight: '600', marginBottom: -1}}>
                            {item.subtitle2}
                        </ListSubheader>

                        {/* Condition to check if list item is a button */}

                        {item.text ? <ListItemButton onClick={()=> {
                            // If button is the language switch it should call this function 
                            if (item.langButton) handleClick()
                            else if (item.logoutButton) handleLogout()
                        }}>
                            <ListItemIcon sx={{minWidth: 0}}>
                                {item.icon 
                                    // Swaps icons depending on the lang
                                    ? item.icon 
                                    : (currentLang === 'en') 
                                    ? <img src={ukFlag} className='iconFlag'/> 
                                    : <img src={nlFlag} className='iconFlag'/>
                                }
                            </ListItemIcon>
                            {item.langButton 
                                ? <ListItemText primary={
                                    // if lang button use logic to toggle text
                                    (currentLang === 'en') 
                                    ? 'English' 
                                    : 'Nederlands'
                                    } /> 
                                    // else use default button text 
                                    : <ListItemText primary={t(item.text)} />
                                }
                            

                            {/* langMenu is useState to toggle icons */}

                            {!langMenu ? item.expandLessIcon : item.expandMoreIcon}
                        </ListItemButton> : null}

                        {/* Condition to toggle collapse component */}

                        {item.langButton ? <Collapse in={langMenu} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                            <ListItemButton 
                                sx={{ pl: 4}} 
                                onClick={()=> {changeLang()}}>
                                {(currentLang === 'nl') 
                                    ? <img src={ukFlag} className='iconFlag'/>  
                                    : <img src={nlFlag} className='iconFlag'/>
                                }
                                <ListItemText className="menuListItem" primary={
                                    // Toggle language button text
                                    (currentLang === 'nl') 
                                    ? 'English' 
                                    : 'Nederlands'
                                    }
                                 />
                            </ListItemButton>
                            </List>
                        </Collapse> : null}
                        
                        {item.divider}
                    </div>
                })}
            </List>
        </Menu>
    )
}