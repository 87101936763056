import {useAuth0} from "@auth0/auth0-react";
import {useEffect} from "react";
import {container} from "tsyringe";
import {AuthorizationService} from "../../../utils/services/authorization.service";
import {IAuthorizationService} from "../../../utils/services/interfaces/iAuthorization.service";

// since we can't use auth0 in http wrapper, on 401 error user is redicted on this route
export const ExpiredToken = () => {
    const authorizationService: IAuthorizationService = container.resolve(AuthorizationService);
    const {logout} = useAuth0();

    useEffect(() => {
        //TODO: replace with stylish alert
        alert(`Your token has expired. You will be logged out from the app`);
        authorizationService.removeUserFromLocalStorage();
        logout({returnTo: window.location.origin});
    });

    return (
        <></>
    )
}