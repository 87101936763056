import {NoRowsOverlayPropsOverrides} from "@mui/x-data-grid";
import {i18next} from "../index";

const NetworkErrorMessages = () => {

    let networkErrorLoaderMessage: NoRowsOverlayPropsOverrides = {
        showIcon: "filter_list_off",
        title: i18next.t('network-error.loader-message.title'),
        description: i18next.t('network-error.loader-message.sub-title')
    };

    return networkErrorLoaderMessage;
}


export default NetworkErrorMessages;