import {injectable} from "tsyringe";
import {httpClient} from "../httpClient";
import {IReportingHttpService} from "./interfaces/IReportingHttp.service";
import {GetDealsPerformanceParams} from "../../modules/views/Reporting/models/Deal";

@injectable()
export class ReportingHttpService implements IReportingHttpService {

    getAllDealsAsync = async (params:GetDealsPerformanceParams) => {
        return await httpClient.get(
            process.env.API_URL + '/report/deals', {
                params: {
                    startDate: params.startDate.toISOString().split('T')[0],
                    endDate: params.endDate.toISOString().split('T')[0],
                    search: params.searchText,
                    page: params.page,
                    take: params.take,
                    sortDirection: params.sortDirection,
                    sortBy: params.sortBy
                }
            });
    }

    getDealsSummaryAsync = async (startDate: Date, endDate: Date) => {
        return await httpClient.get(
            process.env.API_URL + '/report/deals-summary', {
                params: {
                    startDate: startDate.toISOString().split('T')[0],
                    endDate: endDate.toISOString().split('T')[0]
                }
            });
    }
}

export default ReportingHttpService;