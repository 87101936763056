import { Status} from '../../modules/views/CreativesList/models/CreativesList';
import {httpClient} from "../httpClient";


export interface GetAllCreativesParams {
	page: number;
	take: number;
	sortby: number;
	sortdirection: string;
	status?: Status;
	type?: string[];
	createdDate?:string;
}

class CreativesListService {
	static getAll(params: GetAllCreativesParams) {
		return httpClient.get('/creatives',{
			params: params
		});
	}

	static updateStatus(creativeId: string, status: string, rejectReason?: string) {
		return httpClient.patch(`/creatives/${creativeId}`, {
			status, rejectReason
		});
	}

	static getById(creativeId?: string) {
		return httpClient.get(`/creatives/${creativeId}`);
	}

	static getCategories(dspId?: string) {
		return httpClient.get(`/categories?dspId=${dspId}`);
	}

	static addCategories(creativeId?: string,categoryIds? :string[]) {
		return httpClient.post(`/creatives/${creativeId}/categories`,{ids:categoryIds});
	}
	static deleteCategories(creativeId?: string, categoryIds? :string[]) {
		return httpClient.delete(`/creatives/${creativeId}/categories`,{
			data: { ids: categoryIds },
		});
	}
}

export default CreativesListService;
