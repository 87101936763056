import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { useTheme } from "@mui/material";

declare module '@mui/x-data-grid' {
    interface NoRowsOverlayPropsOverrides {
        showIcon: string;
        title: string;
        description: string;
    }

    interface NoResultsOverlayPropsOverrides {
        showIcon: string;
        title: string;
        description: string;
    }
};

const NoData = ({showIcon, title, description}: {showIcon: string, title: string, description: string}) => {
    const theme = useTheme();

    const Item = styled('div')(() => ({
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px 0',
        gap: 1,
        flex: 'none',
        order: 2,
        alignSelf: 'stretch',
        flexGrow: 0,
        color: theme.palette.text.secondary
    }));

    return(
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Item>
                    <span style={{fontSize: '40px', color: theme.palette.primary.main}} className="material-symbols-rounded size-24">{showIcon}</span>
                    <Typography variant="h6">{title}</Typography>
                    <Typography variant="body1">{description}</Typography>
                </Item>
            </Grid>
        </Grid>
    )
};

export default NoData;
