import {injectable} from "tsyringe";
import {GetAllEnabledDspsParams} from "../../typings/api-models/dsp.models";
import {httpClient} from "../httpClient";
import {IDspHttpService} from "./interfaces/iDspHttp.service";

@injectable()
export class DspHttpService implements IDspHttpService {

    getAllEnabledDsps = async (params: GetAllEnabledDspsParams) => {
        return await httpClient.get(
            process.env.API_URL + '/config/connected-dsp', {
                params: params
            });
    }
}

export default DspHttpService;