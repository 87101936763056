import "reflect-metadata";
import ReactDOM from 'react-dom'
import {AppState, Auth0Provider} from '@auth0/auth0-react';
import {App} from './App'
import {I18nextProvider} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next'
import locale_en from './locales/en/locale.json'
import {HashRouter as Router} from 'react-router-dom';
import {IAuthorizationService} from './utils/services/interfaces/iAuthorization.service';
import {container} from 'tsyringe';
import {AuthorizationService} from './utils/services/authorization.service';
import {ILoggerService} from "./utils/services/interfaces/iLoggerService";
import {LoggerService} from "./utils/services/loggerService";
import {createBrowserHistory} from "history";
import {LogVerbosityLevel} from "./typings/enums/logVerbosityLevel.enum";
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

i18next
    .use(LanguageDetector)
    .init({
        supportedLngs: ['en'],
        nonExplicitSupportedLngs: true,
        load: 'languageOnly',
        lng: 'en', //(localStorage.getItem('i18nextLng') === 'nl') ? 'nl' : 'en', TODO uncomment when multiple languages are supported
        fallbackLng: 'en',
        resources: {
            en: {
                locale: locale_en
            }
        },
        defaultNS: "locale",
        detection: {
            order: ['localStorage', 'navigator', 'htmlTag']
        },
    })

const authorizationService: IAuthorizationService = container.resolve(AuthorizationService);
const loggerService: ILoggerService = container.resolve(LoggerService);
const history = createBrowserHistory();

ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <Router>
            <QueryClientProvider client={queryClient}>
            <Auth0Provider
                domain={authorizationService.getAuth0Domain()}
                clientId={authorizationService.getAuth0ClientId()}
                audience={authorizationService.getAuth0Audience()}
                redirectUri={window.location.origin}
                cacheLocation="localstorage"
                useRefreshTokens={true}
                onRedirectCallback={async (appState?: AppState) =>{
                    await authorizationService.onLoginRedirect(appState);
                    loggerService.logMessage(`User successfully authorized`, LogVerbosityLevel.Information);
                    history.replace(appState?.returnTo || window.location.pathname);
                }}>
                <App/>
            </Auth0Provider>
            </QueryClientProvider>
        </Router>
    </I18nextProvider>

    , document.getElementById('root')
)

export {i18next};