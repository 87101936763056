import {
  Breadcrumbs as ClassicBreadcrumbs,
  Typography,
} from "@mui/material";
import { 
    useLocation, 
    Link as LinkRouter 
} from "react-router-dom";
import { BreadcrumbProps } from "../../../typings/common.types";

const Breadcrumbs = ({customBreadcrumbs, customBreadcrumbFunctionality}: BreadcrumbProps ) => {
  const location = useLocation();
  let pathnames = location.pathname.split("/").filter((x) => x);
  if (customBreadcrumbs) {
      pathnames = customBreadcrumbs.split("/").filter((x) => x);
  }

    const handleClick = (to: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (customBreadcrumbFunctionality?.onClicked) {
            event.preventDefault();
            customBreadcrumbFunctionality.onClicked(to);
        }
    };
      
  return (
    <ClassicBreadcrumbs className="page-breadcrumbs" aria-label="breadcrumb" separator={<span className="material-symbols-rounded size-20">chevron_right</span>}>
        <LinkRouter className="breadcrumbs-link" color="inherit" to="/" onClick={handleClick("/")}>
            Home
        </LinkRouter>
      {pathnames.map((name, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        name = name.charAt(0).toUpperCase() + name.slice(1);

        return last ? (
          <Typography color="text.primary" key={to}>
            {name}
          </Typography>
        ) : (
            <LinkRouter className="breadcrumbs-link" color="inherit" to={to} key={to} onClick={handleClick(to)}>
                {name}
            </LinkRouter>
        );
      })}
    </ClassicBreadcrumbs>
  );
};

export default Breadcrumbs;
