import React, { useState } from 'react';
import { Typography, Box, Grid, Card, CardContent } from "@mui/material";
import {IDealSummaryResponse} from "../../../../typings/api-models/deal.models";
import {toCurrency, toPercentage} from "../../../../utils/formatter";

const SmallCardsContainer = ({dealsSummary}: { dealsSummary: IDealSummaryResponse }) => {

    const cardsItems = [
        {
            id: 1,
            text: "Bid requests",
            iconValue: "analytics",
            calculatedValue: dealsSummary.total_bids.toLocaleString(),
            style: {paddingRight: '8px'}
        },
        {
            id: 2,
            text: "Bid rate",
            iconValue: "bar_chart",
            calculatedValue: toPercentage(dealsSummary.winning_bids / dealsSummary.total_bids),
            style: {paddingRight: '8px'}
        },
        {
            id: 3,
            text: "Fill rate",
            iconValue: "new_releases",
            calculatedValue: toPercentage(dealsSummary.plays / dealsSummary.winning_bids),
            style: {paddingRight: '8px'}
        },
        {
            id: 4,
            text: "eCPM",
            iconValue: "euro",
            calculatedValue: toCurrency(dealsSummary.ecpm),
            style: {paddingRight: '0px'}
        },
    ];


    return(
        <Box sx={{ flexGrow: 1, mb: 3 }}>
            <Grid  container spacing={2}>
                {
                    cardsItems.length > 0 && cardsItems.map(item => (
                        <Grid key={item.id} item xs={3}>
                            <Box sx={item.style}>
                                <Card sx={{ display: 'flex' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                       <CardContent sx={{ display: 'flex', flexDirection: 'row', gap: 1, flex: '0 0 auto', alignItems: 'center', p: '16px 16px 0px' }}>
                                           <span className="material-symbols-rounded size-24">{item.iconValue}</span>
                                           <Typography>{item.text}</Typography>
                                       </CardContent>
                                        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, flex: '0 0 auto', justifyContent: 'center', alignItems: 'start-flex', p: '16px' }}>
                                            <Typography component="div" variant="h5">
                                                {item.calculatedValue}
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Card>
                            </Box>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    )
};

export default SmallCardsContainer;