import React, {useEffect, useState} from 'react';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {MenuItem, FormControl, ListItemText, Checkbox} from '@mui/material';


const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 'fit-content',
            minWidth: 210,
        },
    },
};

const DEFAULT_DATE_RANGE = 'last_7_days';

const timeRanges = [
    {label: 'Today', key: 'today'},
    {label: 'Yesterday', key: 'yesterday'},
    {label: 'Last 7 days', key: 'last_7_days'},
    {label: 'Last 30 days', key: 'last_30_days'},
];

const DateRangeSelector = ({mapDateRange, setUnmounting}: { mapDateRange: (text: string) => void, setUnmounting: any }) => {

    const [dateRangeKey, setDateRangeKey] = useState<string>(DEFAULT_DATE_RANGE);

    const handleChange = (event: SelectChangeEvent<typeof dateRangeKey>) => {
        const {target: timeRange} = event;

        let matchingTimeRange = timeRanges.find(
            (t) => {
                return t.key == timeRange.value
            }
        );

        if (matchingTimeRange !== undefined) {
            setDateRangeKey(matchingTimeRange.key);
            mapDateRange(matchingTimeRange.key);
        }

        setUnmounting(false);
    };

    useEffect(() => {
        // Note: In order to init the app by invoking the parent function, we use the useEffect methos to avoid it to go in a loop
        mapDateRange(DEFAULT_DATE_RANGE);
    }, []);

    return (
        <div>
            <FormControl sx={{minWidth: 210 }} size="small" >
                <Select
                    size="small"
                    id="date-checkbox-select"
                    value={dateRangeKey}
                    onChange={handleChange}
                    MenuProps={MenuProps}
                >
                    {timeRanges.map((time) => (
                        <MenuItem key={time.key} value={time.key}>
                            <ListItemText sx={{m: 0}}  primary={time.label}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

        </div>
    );
};

export default DateRangeSelector;