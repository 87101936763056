// React components imports
import {Sidebar} from "./Layout/Sidebar/Sidebar";
import {TopBar} from "./Layout/TopBar/TopBar";
import {Footer} from "./Layout/Footer/Footer";
import {ContentRoutes} from "./Layout/ContentRoutes";
import {useState} from "react";

//MUI styles imports
import {ThemeProvider} from "@mui/material/styles";
import theme from "../theme/theme";

// Contexts imports
import {sidebarContext} from "../contexts/sidebarContext";
import {ILayoutProps} from "../typings/common.types";

export const Layout = ({}: ILayoutProps) => {
    // Gets the local storage of the sidebar visibility status
    let sidebarOpenStatus = localStorage.getItem("sidebar");
    // use states created for props to the context provider
    const [sidebarVisibility, setSidebarVisibility] = useState(sidebarOpenStatus);
    const [iconRotation, setIconRotation] = useState("");

    return (
        <ThemeProvider theme={theme}>
            <div className="content-wrapper">
                <sidebarContext.Provider value={{sidebarVisibility}}>
                    <Sidebar/>
                </sidebarContext.Provider>
                <div className="content">
                    <sidebarContext.Provider
                        value={{setSidebarVisibility, iconRotation, setIconRotation}}
                    >
                        <TopBar/>
                    </sidebarContext.Provider>
                    <div className="main-content">
                        <ContentRoutes/>
                    </div>
                    <Footer />
                </div>
            </div>
        </ThemeProvider>
    );
};
