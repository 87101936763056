import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Creative, Status} from "../../models/CreativesList";
import {Tooltip} from "@mui/material";
import Check from "@mui/icons-material/Check";
import {ActionButton} from "../../CreativesList.styled";
import {useTranslation} from "react-i18next";

interface Props {
    creative: Creative;
    handleCreativeApproval: Function;
    type: string;
}

const ApproveModal = (props: Props) => {

    const [open, setOpen] = React.useState(false);
    const [t, i18n] = useTranslation('locale');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const approve = () => {
        props.handleCreativeApproval(props.creative.id);
        handleClose();
    }

    const renderButton = () => {
        switch (props.type) {
            case 'iconButton':
                return (
                    <Tooltip title="Approve">
                    <span>
                        <ActionButton
                            disabled={props.creative.status === Status.APPROVED}
                            colorVariant='approve'
                            onClick={handleClickOpen}
                            data-testid='creatives-list-approve-button'>
                            <span className='material-symbols-rounded size-24'>check</span>
                        </ActionButton>
                    </span>
                    </Tooltip>
                );
            case 'button':
                return (
                    <Button
                        disabled={props.creative.status === Status.APPROVED}
                        onClick={handleClickOpen}
                        data-testid='creatives-list-approve-button'
                        variant="contained"
                        size="medium"
                        color='success'
                        startIcon={<span className="material-symbols-rounded size-24">{'check'}</span>}>
                        {t('creativeDetails.pageTitleButtons.approve')}
                    </Button>
                );
        }

    };

    return (
        <>
            {renderButton()}

            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('creatives.approval-dialog.title')}
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        {t('creatives.approval-dialog.body', {adid: props.creative.adid})}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>
                        {t('creatives.approval-dialog.cancel-button')}
                    </Button>
                    <Button onClick={() => approve()} autoFocus variant="contained">
                        {t('creatives.approval-dialog.approve-button')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ApproveModal;