import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, TextField, InputAdornment, FormControl, IconButton} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import {debounce} from "lodash";
import Close from "@mui/icons-material/Close";

const debouncedHandleSearchChange = debounce((value, setSearchText) => {
    setSearchText(value);
}, 500);

const SearchFilter = ({
                          placeholder,
                          searchText,
                          setSearchText,
                          isInputFocused,
                          setInputFocused,
                          debouncedSearchText,
                          setDebouncedSearchText,
                          setUnmounting
                      }: { placeholder: string, searchText: string, setSearchText: any, isInputFocused: boolean,
                        setInputFocused:any,debouncedSearchText: string, setDebouncedSearchText: any, setUnmounting: any }) => {
    const componentRef = useRef(null);

    const handleSearchChange = (event) => {
        const {value} = event.target;
        setInputFocused(true);
        setDebouncedSearchText(value);
        debouncedHandleSearchChange(value, setSearchText);
        setUnmounting(false);
    };

    const emptySearchField = (): void => {
        setSearchText("");
        setInputFocused(true);
        setDebouncedSearchText("");
        setUnmounting(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (componentRef.current && !componentRef.current.contains(event.target)) {
                setInputFocused(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <Box
            sx={{
                maxWidth: '100%',
            }}
        >
            <FormControl sx={{width: '100%'}}>

                <TextField
                    value={debouncedSearchText}
                    size="small"
                    variant="outlined"
                    onChange={handleSearchChange}
                    inputRef={input => isInputFocused && input && input.focus()}
                    placeholder={placeholder}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                style={{cursor: 'pointer'}}
                                onClick={emptySearchField}
                            >
                                {debouncedSearchText && (
                                    <CloseIcon/>
                                )}
                            </InputAdornment>
                        )
                    }}
                />
            </FormControl>
        </Box>
    )
};

export default SearchFilter;