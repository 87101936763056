import React from 'react';
import {useTranslation} from "react-i18next";
import Snackbar from "@mui/material/Snackbar";

const CopyToClipboardSnackbar =({ snackbarState, setSnackbarState}: { snackbarState: any, setSnackbarState:any}) => {

    const [t, i18n] = useTranslation('locale');

    const handleCloseSnackBar = () => {
        setSnackbarState({...snackbarState, open: false});
    };

    return (
            <Snackbar
                anchorOrigin={{vertical: snackbarState.vertical, horizontal:snackbarState.horizontal}}
                open={snackbarState?.open}
                autoHideDuration={7000}
                onClose={handleCloseSnackBar}
                message={t("copy-to-clipboard.copy-success-message")}
                key={snackbarState.vertical + snackbarState.horizontal}
            />
    )
};

export default CopyToClipboardSnackbar;
