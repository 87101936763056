import React, {useState} from 'react';
import {Tab, Tabs} from '@mui/material';
import {useTranslation} from "react-i18next";
import {TabMenuWrapper, TabPanel, tabsProps} from "../../Reporting/components/ReportingTabs";
import {CreativeDetailsTabType} from "../enum/CreativeDetailsTabType";
import CreativePreview from "./subViews/CreativePreview";
import {Creative} from '../models/CreativesList';
import IABCategoriesPreview from "./subViews/IABCategoriesPreview";
import CreativeDetailsDetails from "./subViews/CreativeDetailsDetails";

interface Props {
    creative: Creative;
    updateCreative: (updatedCreative: Creative) => void;
}

const CreativeDetailsTabs = ({creative, updateCreative}: Props) => {
    const [t, i18n] = useTranslation('locale');
    const [value, setValue] = useState(CreativeDetailsTabType.Preview);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <TabMenuWrapper>
                <Tabs value={value} onChange={handleChange} aria-label="tabs menu">
                    <Tab label={t("creativeDetails.tabMenu.preview")} {...tabsProps(CreativeDetailsTabType.Preview)} />
                    <Tab label={t("creativeDetails.tabMenu.categories")} {...tabsProps(CreativeDetailsTabType.IABCategories)} />
                    <Tab label={t("creativeDetails.tabMenu.details")} {...tabsProps(CreativeDetailsTabType.Details)} />
                </Tabs>
            </TabMenuWrapper>

            <TabPanel value={value} index={CreativeDetailsTabType.Preview}>
                <CreativePreview creative={creative}/>
            </TabPanel>
            <TabPanel value={value} index={CreativeDetailsTabType.IABCategories}>
                <IABCategoriesPreview creative={creative} updateCreative={updateCreative}/>
            </TabPanel>
            <TabPanel value={value} index={CreativeDetailsTabType.Details}>
                <CreativeDetailsDetails creative={creative}></CreativeDetailsDetails>
            </TabPanel>
        </>
    )
};

export default CreativeDetailsTabs;