import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Creative, Status} from "../../models/CreativesList";
import {Box, TextareaAutosize, TextField, Tooltip} from "@mui/material";
import {ActionButton} from "../../CreativesList.styled";
import Close from "@mui/icons-material/Close";
import {useTranslation} from "react-i18next";
import {FormEvent} from "react";

interface Props {
    creative: Creative;
    handleCreativeRejection: Function;
    type: string;
}

const RejectModal = (props: Props) => {

    const [open, setOpen] = React.useState(false);
    const [t, i18n] = useTranslation('locale');
    const [note, setNote] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setNote('');
        setOpen(false);
    };

    const reject = () => {
        props.handleCreativeRejection(props.creative.id, note);
        handleClose();
    }
    const renderButton = () => {
        switch (props.type) {
            case 'iconButton':
                return (<Tooltip title="Reject">
                <span>
                    <ActionButton
                        disabled={props.creative.status === Status.REJECTED}
                        colorVariant='reject'
                        onClick={handleClickOpen}
                        data-testid='creatives-list-reject-button'>
                        <span className='material-symbols-rounded size-24'>close</span>
                    </ActionButton>
                </span>
                    </Tooltip>
                );
            case 'button':
                return (
                    <Button
                        disabled={props.creative.status === Status.REJECTED}
                        onClick={handleClickOpen}
                        data-testid='creatives-list-approve-button'
                        variant="contained"
                        size="medium"
                        color='error'
                        startIcon={<span className="material-symbols-rounded size-24">{'close'}</span>}>
                        {t('creativeDetails.pageTitleButtons.reject')}
                    </Button>
                );
        }
    }
    return (
        <>
            {renderButton()}

            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('creatives.rejection-dialog.title')}
                </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText id="alert-dialog-description">
                            {t('creatives.rejection-dialog.body', {adid: props.creative.adid})}
                        </DialogContentText>
                        <Box
                            noValidate
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                            }}
                        >
                            <TextField
                                fullWidth
                                label={t('creatives.rejection-dialog.note')}
                                id="note"
                                margin="normal"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()}>
                            {t('creatives.rejection-dialog.cancel-button')}
                        </Button>
                        <Button onClick={() => reject()} autoFocus variant="contained">
                            {t('creatives.rejection-dialog.reject-button')}
                        </Button>
                    </DialogActions>
            </Dialog>
        </>
    );
}

export default RejectModal;