import React, {useState} from 'react';
import {Box, FormControl, Select, Tab, Tabs, Typography} from '@mui/material';
import {useTranslation} from "react-i18next";
import {styled} from "@mui/system";
import MenuItem from "@mui/material/MenuItem";
import {Status} from "../../CreativesList/models/CreativesList";
import DateRangeSelector from "./DateRangeSelector";
import Overview from "./subViews/Overview";
import DealsList from "./subViews/DealsList";
import {ReportingTabType} from "../enum/reportingTabType";


export const TabMenuWrapper = styled('div')({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
});

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
};

export const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            style={{
                width: '100%',
            }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{display: 'flex', maxWidth: '100%'}}>
                    {children}
                </Box>
            )}
        </div>
    );
};

export const tabsProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const ReportingTabs = ({startDate, endDate, mapDateRange}: { startDate ?: Date, endDate ?: Date, mapDateRange: (text: string) => void }) => {
    const [t, i18n] = useTranslation('locale');
    const [value, setValue] = useState(ReportingTabType.Overview);
    const [unmounting, setUnmounting] = useState(false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setUnmounting(false);
    };

    return (
        <>
            <TabMenuWrapper>
                <Tabs value={value} onChange={handleChange} aria-label="tabs menu">
                    <Tab label={t("reporting.tabMenu.overview")} {...tabsProps(ReportingTabType.Overview)} />
                    <Tab label={t("reporting.tabMenu.dealPerformance")} {...tabsProps(ReportingTabType.DealPerformance)} />
                </Tabs>

                <DateRangeSelector
                    mapDateRange={mapDateRange}
                    setUnmounting={setUnmounting}
                />
            </TabMenuWrapper>

            <TabPanel value={value} index={ReportingTabType.Overview}>
                <Overview
                    startDate={startDate}
                    endDate={endDate}
                    tabChange={setValue}
                    unmounting={unmounting}
                    setUnmounting={setUnmounting}
                />
            </TabPanel>
            <TabPanel value={value} index={ReportingTabType.DealPerformance}>
                <DealsList
                    startDate={startDate}
                    endDate={endDate}
                    unmounting={unmounting}
                    setUnmounting={setUnmounting}
                />
            </TabPanel>
        </>
    )
};


export default ReportingTabs;
