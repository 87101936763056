import { Toaster as ReactToaster } from 'react-hot-toast';
import theme from './../../../theme/theme';
import { ErrorIcon, SuccessIcon } from './Toaster.styled';

const Toaster = () => (
	<ReactToaster
		toastOptions={{
			// Define default options
			duration: 6000,
			position:"top-right",
			style: {
				fontSize: '14px',
				lineHeight: '20.02px',
				letterSpacing: '0.15px',
			},

			// Default options for specific types
			success: {
				icon: <SuccessIcon />,
				style: {
					background: theme.palette.success.main,
					color: theme.palette.text.primary
				}
			},
			error: {
				duration: 15000,
				icon: <ErrorIcon />,
				style: {
					background: theme.palette.error.main,
					color: theme.palette.text.primary
				}
			}
		}}
		containerStyle={{
			top: '74px',
			marginRight: '24px'
		}}
	/>
);

export default Toaster;
