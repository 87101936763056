import * as React from "react";
import { ListItemIcon, ListItemText, ListItemButton } from "@mui/material";

import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation } from "react-router-dom";

// Locale imports
import { useTranslation } from "react-i18next";
import { IAuthorizationService } from "../../../utils/services/interfaces/iAuthorization.service";
import { AuthorizationService } from "../../../utils/services/authorization.service";
import { container } from "tsyringe";
import { ILoggerService } from "../../../utils/services/interfaces/iLoggerService";
import { LoggerService } from "../../../utils/services/loggerService";
import { LogVerbosityLevel } from "../../../typings/enums/logVerbosityLevel.enum";
import { ISidebarMenuItem } from "../../../typings/models/iSideBarMenuItem";
import { useEffect } from "react";

export const MenuItems = () => {
  const authorizationService: IAuthorizationService = container.resolve(AuthorizationService);
  const loggerService: ILoggerService = container.resolve(LoggerService);

  const [t] = useTranslation("locale");
  const { logout } = useAuth0();

  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const location = useLocation();

  useEffect(() => {
    var locationStart = location.pathname.split("/")[1];
    const match = menuItems.find(item => item.path.includes(locationStart));
    if (match) setSelectedIndex(match.id);
  }, [location]);

  const onNavigateToPageClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
  };

  // Handles log out with auth0
  const handleLogout = () => {
    loggerService.logMessage(`User about to log off`, LogVerbosityLevel.Information);
    authorizationService.removeUserFromLocalStorage();
    logout({ returnTo: window.location.origin });
  };

  const menuItems: ISidebarMenuItem[] = [];
  menuItems.push({
    id: 1,
    text: "sidebar.reporting",
    icon: <span className="material-symbols-rounded size-24">query_stats</span>,
    path: '/',
    position: 'relative',
    bottom: 0,
    onClick: onNavigateToPageClick
  });
  menuItems.push({
    id: 2,
    text: "sidebar.creatives",
    icon: <span className="material-symbols-rounded size-24">photo</span>,
    path: '/creative-approval',
    position: 'relative',
    bottom: 0,
    onClick: onNavigateToPageClick
  });
  menuItems.push({
    id: 3,
    text: "sidebar.deals",
    icon: <span className="material-symbols-rounded size-24">new_releases</span>,
    path: '/deals',
    position: 'relative',
    bottom: 0,
    onClick: onNavigateToPageClick
  });
  menuItems.push({
    id: 5,
    text: "sidebar.signOut",
    icon: <span className="material-symbols-rounded size-24">logout</span>,
    path: '#',
    position: 'fixed',
    bottom: 16,
    onClick: handleLogout
  });

  return (
      <>
        {menuItems.map((item) => (
            <div key={item.id} id="sidebarLink" className="sidebar-link">
              {item.text ? (
                  <Link to={item.path} >
                    <ListItemButton
                        selected={item.id === selectedIndex}
                        key={item.id}
                        sx={{
                          position: item.position,
                          bottom: item.bottom,
                        }}
                        onClick={(event) => {
                          item.onClick !== null && item.onClick(event, item.id);
                        }}
                    >
                      <ListItemIcon color="inherit" className="sidebar-link-icon">
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText primary={t(item.text)} className="sidebar-link-text" />
                    </ListItemButton>
                  </Link>
              ) : null}
            </div>
        ))}
      </>
  );
};
