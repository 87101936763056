// React imports
import { useNavigate } from "react-router-dom";
// Logos
import logo from "../../../assets/images/logos/logo-primary.png";
import avatarSrc from "../../../assets/avatar/Avatar.png";
// MUI Components imports
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from '@mui/material/ListItemAvatar';

import { MenuItems } from "./MenuItems";
import { IAuthorizationService } from "../../../utils/services/interfaces/iAuthorization.service";
import { AuthorizationService } from "../../../utils/services/authorization.service";
import { container } from "tsyringe";
import { IAuthUser } from "../../../typings/common.types";

export const Sidebar = () => {
  const authorizationService: IAuthorizationService = container.resolve(AuthorizationService);
  const currentUser: IAuthUser = authorizationService.getCurrentUser();

  function navigateToDashboard(): void {
    navigate("", { replace: true });
  }

  // Sets initial state of the sidebar from the context provider
  let navigate = useNavigate();

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      classes={{ paper: "sidebar-drawer" }}
    >
      <List
        className="top-content"
      >
        <ListItem className="logo-container">
          <img
            src={logo}
            onClick={navigateToDashboard}
          />
        </ListItem>
      </List>
      <List className="main-nav">
        <MenuItems></MenuItems>
      </List>
    </Drawer>
  );
};
