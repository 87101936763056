// React router imports
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import ProtectedRoute from "./ProtectedRoute";
import { ExpiredToken } from "../views/ErrorPages/expiredToken";
import CreativesList from "../views/CreativesList";
import Reporting from "../views/Reporting/Reporting";
import Deals from "../views/Deals/Deals";
import NewDeal from "../views/Deals/NewDeal";
import CreativeDetails from "../views/CreativesList/CreativeDetails";

export const ContentRoutes = ()=> {
    const [t] = useTranslation('locale');
    
    return(
        <Routes>
            <Route path='/' element={<ProtectedRoute />}>
                <Route index element={<Reporting />} />
            </Route>
            <Route path='/creative-approval' element={<ProtectedRoute />}>
                <Route index element={<CreativesList />} />
            </Route>
            <Route path='/creative-approval/creative-details/:creativeId' element={<ProtectedRoute />}>
                <Route index element={<CreativeDetails />} />
            </Route>
            <Route path='/deals' element={<ProtectedRoute />}>
                <Route index element={<Deals />} />
            </Route>
            <Route path='/deals/new' element={<ProtectedRoute />}>
                <Route index element={<NewDeal />} />
            </Route>
            <Route path='/deals/edit/:dealId' element={<ProtectedRoute />}>
                <Route index element={<NewDeal />} />
            </Route>

            {/* expired token */}
            <Route path='/expired-token' element={<ProtectedRoute />}>
                <Route index element={<ExpiredToken />} />
            </Route>
        </Routes>
    )
}