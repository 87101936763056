import {IDealStatusUpdateRequest} from "../../../../typings/api-models/deal.models";

export interface DealValidationModel {
    dealName: string;
    auctionType: string;
    bidFloorCpm: string;
    bidFloorCurrency: string;
}

export enum DealStatus {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE'
}

export function getDealStatusFromString(statusString: string): DealStatus | undefined {
    const statusKeys = Object.keys(DealStatus) as DealStatus[];
    return statusKeys.find((status) => DealStatus[status] === statusString);
}

export interface DealMutationParams {
    dealId: string | '';
    request: IDealStatusUpdateRequest;
}

export interface DealStatusDialogParams {
    title: string;
    description: string;
    confirmationButton: string;
}

export const defaultDealStatusDialog : DealStatusDialogParams = {
    title: "",
    description: "",
    confirmationButton: ""
};
