import React from 'react';
import Chip from "@mui/material/Chip";
import {Box} from "@mui/system";
import {Typography} from "@mui/material";

interface FilterChipProps {
    filterVariant: string;
    filterStatus: string;
    onDelete: () => void;
}

const FilterChip: React.FC<FilterChipProps> = ({filterVariant, filterStatus, onDelete}) => {
    return (
        <Chip
            label={
                <Box>
                    <Typography variant="body1" fontWeight="bold" component="span">
                        {filterVariant}{": "}
                    </Typography>
                    <Typography variant="body1" component="span">
                        {filterStatus}
                    </Typography>
                </Box>
            }
            onDelete={onDelete}
            color="default"
            variant="outlined"
            size="small"
        />
    );
};

export default FilterChip;