import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card, CardActions, Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    Grid, Icon,
    IconButton,
    InputAdornment,
    InputLabel,
    Link, ListItemText,
    MenuItem, OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
// Lumina components
import {PageTitle} from "../../common/pageTitle";
import {useTranslation} from "react-i18next";
import {setTabTitle} from "../../common/tabTitle";
import InfoIcon from '@mui/icons-material/Info';
import PaymentsIcon from '@mui/icons-material/Payments';
import ManageAccounts from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { IDealHttpService } from '../../../utils/services/interfaces/iDealHttp.service';
import { IDspHttpService } from '../../../utils/services/interfaces/iDspHttp.service';
import DealHttpService from '../../../utils/services/dealHttpService';
import DspHttpService from '../../../utils/services/dspHttpService';
import { container } from 'tsyringe';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import {
    IBaseDeal,
    IDealCreationRequest,
    IDealCreationResponse,
    IDealEditRequest,
    IDealEditResponse,
    IDealListItem
} from '../../../typings/api-models/deal.models';
import { iLdskHttpError } from '../../../typings/api-models/commonApi.models';
import toast from 'react-hot-toast';
import Toaster from '../../common/Toaster/Toaster';
import {dealStatusMap, dealTypes, mimeTypesMap} from '../../../utils/formatter';
import { DealValidationModel } from './models/Deals';
import { ArrowBack } from '@mui/icons-material';
import { CustomAlert } from '../../common/CustomAlert';
import { SnackbarState } from './DealsGridView';
import MultipleSelectFilter from "../../common/Toolbar/MultipleSelectFilter";
import dspHttpService from "../../../utils/services/dspHttpService";
import {GetAllEnabledDspsParams, IBaseDsp, IDspListResponse} from "../../../typings/api-models/dsp.models";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingIndicator from "../../common/LoadingIndicator";


const NewDeal = () => {
    const [t, i18n] = useTranslation('locale');
    setTabTitle(t('deals.tabTitle'));

    const dealHttpService: IDealHttpService = container.resolve(DealHttpService);
    const dspHttpService: IDspHttpService = container.resolve(DspHttpService);
    const { dealId } = useParams();

    const defaultModel : DealValidationModel = {
        dealName: "",
        auctionType: "",
        bidFloorCurrency: "",
        bidFloorCpm: ""
    };

    const bidFloorTypeHelperText = t('deals.new-deal.bidfloor-helper-text');
    const twoDecimalNumberRegex = /^[0-9]*(\.[0-9]{0,2})?$/;

    const [auctionType, setAuctionType] = useState("");
    const [currencyType, setCurrencyType] = useState("");
    const [dealName, setDealName] = useState("");
    const [bidFloorCpm, setBidFloorCpm] = useState("");
    const [pageTitle, setPageTitle] = useState(() => t('deals.new-deal.title'));
    const [editMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dealStatus, setDealStatus] = useState("");

    const [initialModel, setInitialModel] = useState(defaultModel);

    const [auctionTypeError, setAuctionTypeError] = useState(false);
    const [currencyTypeError, setCurrencyTypeError] = useState(false);
    const [dealNameErrorMessage, setDealNameErrorMessage] = useState("");
    const [bidFloorCpmErrorMessage, setBidFloorCpmErrorMessage] = useState(bidFloorTypeHelperText);
    const [availableDsps, setAvailableDsps] = useState<IBaseDsp[]>([]);
    const [currentDsps, setCurrentDsps] = useState<IBaseDsp[]>([]);
    const [dspError, setDspError] = useState(false);
    const [dealStatusError, setDealStatusError] = useState(false);

    const [cancelChanges, setCancelChanges] = useState(false);
    const [navigationChange, setNavigationChange] = useState("");
    const navigate = useNavigate();

    const [snackbarState, setSnackbarState] = useState<SnackbarState>({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    });
    const { vertical, horizontal, open } = snackbarState;

    const handleCopyToClipboard = (e: any, id: string, newState: SnackbarOrigin) => {
        e.stopPropagation();

        navigator.clipboard.writeText(id).then(() => {
            setSnackbarState({ open: true, ...newState });

        },() => {
            toast.error(t('deals.notifications.copy-to-clipboard-failed'));
        });
    };

    const handleCloseSnackBar = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };

    useEffect(() => {
        const params: GetAllEnabledDspsParams = {
            page: 1,
            take: 10,
        };
        setIsLoading(true);
        dspHttpService.getAllEnabledDsps(params)
            .then((response: AxiosResponse<IDspListResponse>) => {
                setAvailableDsps(response.data.dsps);
                setIsLoading(false);
            })
            .catch((errorResponse: iLdskHttpError) => {
                setIsLoading(false);
                toast.custom(
                    <Alert severity="error">
                        <AlertTitle>{t('deals.notifications.new-deal.title')}</AlertTitle>
                        {t('deals.notifications.new-deal.errors.generic')}
                    </Alert>
                );
                handleDialogRedirect();
            });
    }, []);

    useEffect(() => {
        (async () => {
            if (dealId && dealId.length > 0){
                setIsLoading(true);
                await dealHttpService.getDeal(dealId).then((response: AxiosResponse<IDealListItem>) => {
                    setEditMode(true);
                    setDealName(response.data.deal_name);
                    setAuctionType(response.data.deal_type.toString());
                    setCurrencyType(response.data.currency.toString());
                    setBidFloorCpm(response.data.floor_cpm.toString());
                    setPageTitle(response.data.deal_name);
                    setDealStatus(response.data.status)
                    const selectedDsps = availableDsps.filter(availableDsp => response.data.dsps.includes(availableDsp.id));
                    setCurrentDsps(selectedDsps);

                    let initialModel : DealValidationModel = {
                        dealName: response.data.deal_name,
                        auctionType: response.data.deal_type.toString(),
                        bidFloorCurrency: response.data.currency.toString(),
                        bidFloorCpm: response.data.floor_cpm.toString()
                    };

                    setInitialModel(initialModel);
                    setIsLoading(false);
                    })
                    .catch((errorResponse: iLdskHttpError) => {
                        setIsLoading(false);
                        toast.custom(
                            <Alert severity="error">
                                <AlertTitle>{t('deals.notifications.new-deal.title')}</AlertTitle>
                                {t('deals.notifications.new-deal.errors.generic')}
                            </Alert>
                        );
                        handleDialogRedirect();
                    }
                );
            }
        })();
        return () => {};
      }, [availableDsps]);

    const handleAuctionTypeChange = (event: SelectChangeEvent) => {
        setAuctionType(event.target.value as string);
        if (auctionTypeError && event.target.value?.length > 0)
        {
            setAuctionTypeError(false);
        }
    };

    const handleDealStatusChange = (event: SelectChangeEvent) => {
        setDealStatus(event.target.value as string);
        if (dealStatusError && event.target.value?.length > 0)
        {
            setDealStatusError(false);
        }
    };

    const handleCurrencyTypeChange = (event: SelectChangeEvent) => {
        setCurrencyType(event.target.value as string);
        if (currencyTypeError && event.target.value?.length > 0)
        {
            setCurrencyTypeError(false);
        }
    };

    const handleDspSelectChange = (event: SelectChangeEvent) => {
        const selectedIds = event.target.value as string[];
        const selectedDsps = availableDsps.filter(dsp => selectedIds.includes(dsp.id));
        setCurrentDsps(selectedDsps);
        if (dspError && event.target.value?.length > 0)
        {
            setDspError(false);
        }
    }

    const handleDialogClose = () => {
        setCancelChanges(false);
    }

    const handleOnCancel = (routeName: string) => {
        routeName = routeName.replace('/', '');
        if (dealName != initialModel.dealName || currencyType != initialModel.bidFloorCurrency
            || initialModel.bidFloorCpm != bidFloorCpm || auctionType != initialModel.auctionType) {
            setNavigationChange(routeName);
            setCancelChanges(true);
        } else
            navigate("/" + routeName);
    }

    const handleDialogRedirect = () => {
        navigate("/" + navigationChange);
    }

    const handleOnSaveChanges = async () => {
        let isError = false;
        if (!dealName || (dealName && dealName.trim().length === 0))
        {
            setDealNameErrorMessage(t('deals.validations.errors.dealname'));
            isError = true;
        }

        if (!auctionType){
            setAuctionTypeError(true);
            isError = true;
        }

        if (!dealStatus){
            setDealStatusError(true);
            isError = true;
        }

        if (!bidFloorCpm)
        {
            setBidFloorCpmErrorMessage(t('deals.validations.errors.bidfloorcpm'));
            isError = true;
        }

        if (bidFloorCpm && bidFloorCpm.includes('.') && bidFloorCpm.slice(-1) === '.'){
            setBidFloorCpmErrorMessage(t('deals.validations.errors.bidfloorcpm'));
            isError = true;
        }

        if (bidFloorCpm && (bidFloorCpm === "0" || bidFloorCpm === "0.0" || bidFloorCpm === "0.00"))
        {
            setBidFloorCpmErrorMessage(t('deals.validations.errors.bidfloorcpm'));
            isError = true;
        }

        if (!currencyType){
            setCurrencyTypeError(true);
            isError = true;
        }

        if (!currentDsps || currentDsps.length === 0){
            setDspError(true);
            isError = true;
        }

        if (isError === true)
            return;
        
        if (editMode){
            let request : IDealEditRequest = {
                deal_name: dealName.trim(),
                deal_type: parseFloat(auctionType),
                floor_cpm: parseFloat(bidFloorCpm),
                currency: currencyType,
                dsps: currentDsps.map(dsp => dsp.id),
                status: dealStatus
            }
            await dealHttpService.editDeal(dealId!, request).then((response: AxiosResponse<IDealEditResponse>) => {
                setIsLoading(false);
                navigate("/deals");
                let message = t('deals.validations.success.edit-deal.message').replace("[dealName]", dealName);

                toast.custom(
                    <CustomAlert severity={"success"} title={t('deals.notifications.edit-deal.title')}
                                 message={message} style={{maxWidth: "320px"}}></CustomAlert>
                );
            })
                .catch((errorResponse: iLdskHttpError) => {
                    setIsLoading(false);
                    toast.custom(
                        <CustomAlert severity={"error"} title={t('deals.notifications.edit-deal.title')}
                                     message={t('deals.notifications.edit-deal.errors.generic')} style={{maxWidth: "320px"}}></CustomAlert>
                    );
                });
        }
        else 
        {
            let request : IDealCreationRequest = {
                deal_name: dealName.trim(),
                deal_type: parseFloat(auctionType),
                floor_cpm: parseFloat(bidFloorCpm),
                currency: currencyType,
                dsps: currentDsps.map(dsp => dsp.id),
                status: dealStatus
            }
            await dealHttpService.createNewDeal(request).then((response: AxiosResponse<IDealCreationResponse>) => {
                setIsLoading(false);
                navigate("/deals");
                let message = t('deals.validations.success.new-deal.message').replace("[dealName]", dealName);

                toast.custom(
                    <CustomAlert severity={"success"} title={t('deals.notifications.new-deal.title')} message={message} style={{maxWidth: "320px"}}></CustomAlert>,
                );
                })
                .catch((errorResponse: iLdskHttpError) => {
                    setIsLoading(false);
                    toast.custom(
                        <CustomAlert severity={"error"} title={t('deals.notifications.new-deal.title')} message={t('deals.notifications.new-deal.errors.generic')} style={{maxWidth: "320px"}}></CustomAlert>
                    );
            });
        }
    }

    return (
        <Box>
            {isLoading ? (
                    <LoadingIndicator/>
                ) : (
            <>
                <Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Button onClick={()=>handleOnCancel("deals")} startIcon={<ArrowBackIcon />}>
                            {t('deals.new-deal.back')}
                        </Button>
                    </Box>
                </Box>
                <PageTitle pageTitle={pageTitle} customBreadcrumbs={editMode ? t("bread-crumbs.deals-edit-deal") : t("bread-crumbs.deals-create-new-deal")} customBreadcrumbFunctionality={{ onClicked: handleOnCancel }}></PageTitle>
                    <Grid container sx={{ flexgrow: 1}} direction="column">
                        <Grid item xs={12}>
                            <Card>
                                <Grid container>
                                    <Grid item xs={12} lg={8} md={8}>
                                        <Box>
                                            <Typography sx={{padding: 2}} variant="h6">{t('deals.new-deal.header')}</Typography>
                                        </Box>
                                    </Grid>
                                    {editMode && <Grid item xs={12} lg={4} md={4}>
                                        <Box display="flex" justifyContent="end" sx={{padding: 2}}>
                                            <Chip label="Editing"/>
                                        </Box>
                                    </Grid>}
                                </Grid>
                                <Divider variant="fullWidth"/>
                                <Grid item xs={12} md={12} lg={6} xl={6} sx={{padding: 2}}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <InfoIcon fontSize="small" color="primary"/>
                                            <Typography variant="body1">{t('deals.new-deal.basic-info')}</Typography>
                                        </Stack>
                                    </Grid>
                                    {editMode && <Grid sx={{pt: 2}} item xs={12} lg={12} md={12}>
                                        <TextField 
                                            color="primary" 
                                            label={t('deals.new-deal.deal-id-label')}
                                            fullWidth 
                                            placeholder={t('deals.new-deal.deal-id-label')} 
                                            id="outlined-basic" 
                                            variant="outlined"
                                            disabled
                                            value={dealId}
                                            InputProps={{
                                                endAdornment: (
                                                <InputAdornment position='end'>
                                                    <Tooltip title={t('deals.copy-tooltip-title')} >
                                                        <IconButton
                                                        aria-label='copy-to-clipboard'
                                                        onClick={e => handleCopyToClipboard(e, dealId!, {vertical: 'top',  horizontal: 'right'})}>
                                                            {<span className="material-symbols-rounded size-24">content_copy</span>}
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                                ),
                                            }}
                                            />
                                    </Grid>}
                                    <Grid sx={{pt: 2}} item xs={12} lg={12} md={12}>
                                        <TextField 
                                            color="primary" 
                                            label={t('deals.new-deal.deal-name-label')}
                                            fullWidth 
                                            placeholder={t('deals.new-deal.deal-name-label')} 
                                            id="outlined-basic" 
                                            variant="outlined"
                                            value={dealName}
                                            error={!!dealNameErrorMessage}
                                            helperText={dealNameErrorMessage}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setDealName(event.target.value);
                                                if (dealNameErrorMessage && event.target.value?.length > 0)
                                                {
                                                    setDealNameErrorMessage("");
                                                }
                                            }}/>
                                    </Grid>
                                    <Grid sx={{pt: 2}} item xs={12} lg={12} md={12}>
                                        <Box>
                                            <FormControl color="primary" fullWidth>
                                                <InputLabel id="select_label">{t('deals.new-deal.auction-type-label')}</InputLabel>
                                                <Select
                                                    id="auction_type"
                                                    value={auctionType}
                                                    labelId="select_label"
                                                    label={t('deals.new-deal.auction-type-label')}
                                                    error={!!auctionTypeError}
                                                    onChange={handleAuctionTypeChange}>
                                                    <MenuItem value="1">{dealTypes[1]}</MenuItem>
                                                    <MenuItem value="2">{dealTypes[2]}</MenuItem>
                                                    <MenuItem value="3">{dealTypes[3]}</MenuItem>
                                                </Select>
                                                {auctionTypeError && <FormHelperText error>{t('deals.validations.errors.auction-type')}</FormHelperText>}
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                    <Grid sx={{pt: 2}} item xs={12} lg={12} md={12}>
                                        <Box>
                                            <FormControl color="primary" fullWidth>
                                                <InputLabel id="select_label">{t('deals.new-deal.deal-status-label')}</InputLabel>
                                                <Select
                                                    id="deal_status"
                                                    value={dealStatus}
                                                    labelId="select_label"
                                                    label={t('deals.new-deal.deal-status-label')}
                                                    error={!!dealStatusError}
                                                    onChange={handleDealStatusChange}>
                                                    {Array.from(dealStatusMap).map(([key, value]) => (
                                                        <MenuItem value={key}>{t("deals.statuses." + value.toLowerCase())}</MenuItem>
                                                    ))}
                                                </Select>
                                                {dealStatusError && <FormHelperText error>{t('deals.validations.errors.status')}</FormHelperText>}
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12} lg={6} xl={6} sx={{padding: 2}}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <PaymentsIcon fontSize="small" color="primary"/>
                                            <Typography variant="body1">{t('deals.new-deal.pricing-details')}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid sx={{pt: 2}} item xs={12} lg={12} md={12}>
                                        <TextField 
                                            color="primary" 
                                            label={t('deals.new-deal.bidfloorcpm-label')}
                                            fullWidth 
                                            placeholder={t('deals.new-deal.bidfloorcpm-label')}
                                            helperText={bidFloorCpmErrorMessage}
                                            error={!!bidFloorCpmErrorMessage && bidFloorCpmErrorMessage !== bidFloorTypeHelperText}
                                            id="outlined-basic" 
                                            variant="outlined"
                                            value={bidFloorCpm}
                                            inputProps={{ step: ".01" }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                if (event.target.value === '' || twoDecimalNumberRegex.test(event.target.value)) {

                                                    if (bidFloorCpm.length === 0 && event.target.value === ".")
                                                        return;

                                                    setBidFloorCpm(event.target.value);
                                                    if (bidFloorCpmErrorMessage && event.target.value?.length > 0)
                                                    {
                                                        setBidFloorCpmErrorMessage("");
                                                    }                                              
                                                }
                                        }}/>
                                    </Grid>
                                    <Grid sx={{pt: 2}} item xs={12} lg={12} md={12}>
                                        <Box>
                                            <FormControl color="primary" fullWidth>
                                                <InputLabel id="select_label">{t('deals.new-deal.currency-label')}</InputLabel>
                                                <Select
                                                    id="currency_type"
                                                    value={currencyType}
                                                    labelId="select_label"
                                                    label={t('deals.new-deal.currency-label')}
                                                    error={!!currencyTypeError}
                                                    onChange={handleCurrencyTypeChange}>
                                                    {/* These are hardcoded for now and not in locale they should come from backend */}
                                                    <MenuItem value="USD">USD</MenuItem>
                                                    <MenuItem value="EUR">EUR</MenuItem>
                                                </Select>
                                                {currencyTypeError && <FormHelperText error>{t('deals.validations.errors.currency')}</FormHelperText>}
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12} lg={6} xl={6} sx={{padding: 2}}>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <Icon color="primary">
                                                <span
                                                    className="material-symbols-rounded size-20">manage_accounts</span>
                                            </Icon>
                                            <Typography variant="body1">{t('deals.new-deal.buyer-details')}</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid sx={{pt: 2}} item xs={12} lg={12} md={12}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel
                                                id="dsps-filter-label">{t('deals.new-deal.deal-dsp-list-label')}</InputLabel>
                                            <Select
                                                labelId="dsps-filter-label"
                                                id="dsps-filter"
                                                multiple
                                                value={currentDsps.map(dsp => dsp.id)}
                                                input={<OutlinedInput label={t('deals.new-deal.deal-dsp-list-label')}/>}
                                                error={!!dspError}
                                                onChange={handleDspSelectChange}
                                                renderValue={(selected) => (
                                                    <div>
                                                        {selected.map((value, index) => (
                                                            <span key={value}>
                                                                {availableDsps.find(dsp => dsp.id === value)?.name}
                                                                {index < selected.length - 1 ? ", " : ""}
                                                            </span>
                                                        ))}
                                                    </div>

                                                )}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "left"
                                                    },
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 200,
                                                            width: 250,
                                                        },
                                                    },
                                                }}

                                            >

                                                {availableDsps.map((dsp) => (
                                                    <MenuItem key={dsp.id} value={dsp.id}>
                                                        <Checkbox
                                                            checked={currentDsps.some(selectedDsp => selectedDsp.id === dsp.id)}/>
                                                        <ListItemText primary={dsp.name}/>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {dspError && <FormHelperText error>{t('deals.validations.errors.dsp')}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Divider variant="fullWidth"/>
                                <CardActions sx={{p: '16px'}}>
                                    <Button sx={{mr: "8px"}} variant="outlined" size="medium" color="primary" onClick={() => handleOnCancel("deals")}>
                                        {t('deals.new-deal.cancel-button')}
                                    </Button>
                                    <Button variant="contained" size="medium" color="primary" onClick={handleOnSaveChanges}>
                                        {t('deals.new-deal.save-button')}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </> )}

                <Dialog
                    open={cancelChanges}
                    onClose={handleDialogClose}
                    >
                    <DialogTitle>
                        <Typography>{editMode ? t('deals.edit-deal.dialog.title') : t('deals.new-deal.dialog.title')}</Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText>
                                {t('deals.new-deal.dialog.content')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" size="medium" color="primary" onClick={handleDialogClose}>
                            {t('deals.new-deal.dialog.cancel-button')}
                        </Button>
                        <Button variant="contained" size="medium" color="primary" onClick={handleDialogRedirect}>
                            {t('deals.new-deal.dialog.save-button')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Toaster/>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    autoHideDuration={7000}
                    onClose={handleCloseSnackBar}
                    message={t("deals.copy-deal-id-success-message")}
                    key={vertical + horizontal}
                />
            </Box>
    )
};

export default NewDeal;

