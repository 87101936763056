import Chip from '@mui/material/Chip';
import {styled} from '@mui/material/styles';
import {ComponentsOverrides, IconButton} from "@mui/material";
import {DataGridPremiumProps} from "@mui/x-data-grid-premium";

declare module '@mui/material/styles' {
    export interface Components extends ComponentsOverrides {
        MuiDataGridPremium?: {
            defaultProps?: Partial<DataGridPremiumProps>;
            styleOverrides?: Record<string, any>;
        };
    }
}



export const ActionButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'colorVariant'
})<{
    colorVariant?: 'approve' | 'reject';
}>(({theme, colorVariant}) => {
    const variantColors = {
        approve: theme.palette.success.main,
        reject: theme.palette.error.main,
        default: theme.palette.info.main
    };

    const activeColor = variantColors[colorVariant || 'default'];

    return {
        color: activeColor,
        size: 'small',
        fontSize: 'small'
    };
});

