import React, {useEffect, useState} from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    Box,
    Chip,
    Button,
    Stack,
    Checkbox,
    Grid,
    Autocomplete,
    TextField,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions, Alert, AlertTitle, Icon
} from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {useTranslation} from "react-i18next";
import {Creative, IABCategory, IABCategoryTreeNode} from "../../models/CreativesList";
import {useQuery} from "react-query";
import CreativesListService from "../../../../../utils/services/CreativesListService";
import toast from "react-hot-toast";
import CopyToClipboard from "../../../../common/CopyToClipboard/CopyToClipboard";
import {SnackbarState} from "../../../Deals/DealsGridView";
import CopyToClipboardSnackbar from "../../../../common/CopyToClipboard/CopyToClipboardSnackbar";
import { format } from 'date-fns';

interface Props {
    creative: Creative,
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const CreativeDetailsDetails = ({creative}: Props) => {
    const [t, i18n] = useTranslation('locale');

    const getMaxGridWidth = () => {
        return document.getElementsByClassName('page-title')[0].clientWidth;
    };

    const gcd = (a, b) => {
        return b === 0 ? a : gcd(b, a % b);
    };

    const divisor = gcd(creative.dimensions.width, creative.dimensions.height);
    const simplifiedWidth = creative.dimensions.width / divisor;
    const simplifiedHeight = creative.dimensions.height / divisor;
    const aspectRatioStr = `${simplifiedWidth}:${simplifiedHeight}`;
    const createdDateFormated = format(new Date(creative.create_date), 'yyyy-MM-dd HH:mm');
    const updatedDateFormated = format(new Date(creative.update_date), 'yyyy-MM-dd HH:mm');

    const creativeIcon = () => {
        switch (true) {
            case creative.mime_type.includes('video'):
                return "video_camera_back";
            case creative.mime_type.includes('html'):
                return "html";
            case creative.mime_type.includes('image'):
                return "photo";
            default:
                return null;
        }
    }
    const [snackbarState, setSnackbarState] = useState<SnackbarState>({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    });

    return (
        <Box sx={{width: getMaxGridWidth()}}>
            <Card sx={{display: 'flex', flexDirection: 'column', m: 'auto', alignSelf: 'stretch'}}>
                <CardHeader
                    title={<Typography variant="h6">{t("creativeDetails.Details.title")}</Typography>}
                />
                <CardContent sx={{p: '16px', borderTop: '1px solid #e2e3e4', borderBottom: '1px solid #e2e3e4'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sx={{ height: '40px' }}>
                            <Grid container spacing={1}>
                                <Grid item
                                      xs={2}><Typography color="text.secondary">{t("creativeDetails.Details.list.adId")}</Typography></Grid>
                                <Grid item xs={10} >
                                    <CopyToClipboard
                                        id={'deals-deal-id-' + creative?.adid}
                                        valueToBeCloned={creative?.adid}
                                        setSnackbarState={setSnackbarState}
                                        divClassName={'creative-adid'}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}><Typography color="text.secondary">{t("creativeDetails.Details.list.advertiser")}</Typography></Grid>
                                <Grid item xs={10}><Typography>{creative.advertiser.name}</Typography></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}><Typography color="text.secondary">{t("creativeDetails.Details.list.dsp")}</Typography></Grid>
                                <Grid item xs={10}><Typography>{creative.advertiser.dsp_provider.name}</Typography></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item
                                      xs={2}><Typography color="text.secondary">{t("creativeDetails.Details.list.dealId")}</Typography></Grid>
                                <Grid item xs={10}>
                                    {
                                        creative?.dealid?.length > 0 ?
                                            (
                                                <CopyToClipboard
                                                    id={'deals-deal-id-' + creative?.dealid}
                                                    valueToBeCloned={creative?.dealid}
                                                    setSnackbarState={setSnackbarState}
                                                    divClassName={'creative-adid'}
                                                />
                                            ) :
                                            (<Typography>{t("creativeDetails.Details.noData.deal")}</Typography>)
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item
                                      xs={2}><Typography color="text.secondary">{t("creativeDetails.Details.list.categories")}</Typography></Grid>
                                <Grid item xs={10}>
                                    {
                                        creative?.categories?.length > 0 ?
                                            (creative.categories.map((category, index) => (
                                                    <Chip
                                                        key={Math.random()}
                                                        label={`${category.code} ${category.name}`}
                                                        size="small"
                                                        className="CategoryChip"
                                                        sx={{mr: 1}}
                                                    />
                                                ))
                                            ) :
                                            (<Typography>{t("creativeDetails.Details.noData.categories")}</Typography>)
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}><Typography color="text.secondary">{t("creativeDetails.Details.list.creativeType")}</Typography></Grid>
                                <Grid item xs={10}>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <Icon color="primary">
                                            <span
                                                className={'material-symbols-rounded size-24'}>{creativeIcon()}</span>
                                        </Icon>
                                        <Typography variant="body1"> {creative.mime_type}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}><Typography color="text.secondary">{t("creativeDetails.Details.list.creativeSize")}</Typography></Grid>
                                <Grid item xs={10}><Typography>{creative.dimensions.width}x{creative.dimensions.height}</Typography></Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}><Typography color="text.secondary">{t("creativeDetails.Details.list.aspectRatio")}</Typography></Grid>
                                <Grid item xs={10}><Typography>{aspectRatioStr}</Typography></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}><Typography color="text.secondary">{t("creativeDetails.Details.list.orientation")}</Typography></Grid>
                                <Grid item xs={10}>
                                    <Typography>{creative.dimensions.width > creative.dimensions.height ? t("creativeDetails.Details.list.landscape") : t("creativeDetails.Details.list.portrait")}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}><Typography color="text.secondary">{t("creativeDetails.Details.list.createdOn")}</Typography></Grid>
                                <Grid item xs={10}><Typography>{createdDateFormated}</Typography></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}><Typography color="text.secondary">{t("creativeDetails.Details.list.modifiedOn")}</Typography></Grid>
                                <Grid item xs={10}><Typography>{updatedDateFormated}</Typography></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <CopyToClipboardSnackbar setSnackbarState={setSnackbarState} snackbarState={snackbarState} />
        </Box>
    )
};

export default CreativeDetailsDetails;
