import { Alert, AlertTitle } from "@mui/material"
import {CustomAlertProps} from "../../typings/common.types";

export const CustomAlert = ({severity, title, message, style} : CustomAlertProps)=> {
    return(
        <Alert severity={severity} sx={style}>
            <AlertTitle>{title}</AlertTitle>
            {message}
        </Alert>
    )
}