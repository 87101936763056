import Close from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useState} from 'react';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import {Creative} from '../../models/CreativesList';
import ChipsWrapper from '../../../../common/ChipsWrapper';
import {ActionButton} from '../../CreativesList.styled';

import {Box, DialogTitleProps, IconButton, Tooltip} from "@mui/material";

import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import { useNavigate, useParams } from 'react-router-dom';

interface Props {
    creative: Creative;
}

const CREATIVE_HEIGHT = 540;

const PreviewModal = ({
                          creative: {id, asset_url, mime_type = '', name, dimensions, iab_categories, status},
                      }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isVideo = mime_type.includes('video');
    const height = dimensions && dimensions.height < CREATIVE_HEIGHT ? dimensions.height : CREATIVE_HEIGHT;
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
        navigate(`/creative-approval/creative-details/${id}`, {state: {id, asset_url, mime_type, dimensions, status}});

    };

    const handleClose = () => {
        setOpen(false);
    };

    interface DialogTitleProps {
        id: string;
        children?: React.ReactNode;
        onClose: () => void;
    }

    function BootstrapDialogTitle(props: DialogTitleProps) {
        const {children, onClose, ...other} = props;

        return (
            <DialogTitle sx={{m: 0, p: 2}} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close/>
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }

    return (
        <>
            <Tooltip title="Details">
                <span>
                    <ActionButton onClick={handleClickOpen}>
                        <span className="material-symbols-rounded size-24">info</span>
                    </ActionButton>
                </span>
            </Tooltip>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {name}
                </BootstrapDialogTitle>
                <DialogContent sx={{overflow: 'hidden'}} dividers>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            m: 'auto',
                            maxWidth: 'fit-content',
                        }}
                    >
                        {isVideo ? (
                            // eslint-disable-next-line jsx-a11y/media-has-caption
                            <video style={{objectFit: 'scale-down'}} controls autoPlay height={height} >
                                <source src={asset_url} type={mime_type}/>
                            </video>
                        ) : (
                            <img style={{objectFit: 'scale-down'}} src={asset_url} alt='creative' height={height}/>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PreviewModal;
