
import {useTheme} from "@mui/material";
import {DealStatus} from "../models/Deals";

interface StatusStyleProperties {
    [status: string]: {
        backgroundColor: string;
        textColor: string;
    };
}

const getDealStatusProperties = (status: string): { backgroundColor: string; textColor: string } => {
    const theme = useTheme();

    const dealStatusProperties: StatusStyleProperties = {
        [DealStatus.INACTIVE]: {
            backgroundColor: theme.palette.background.colorNeutral100,
            textColor: theme.palette.text.primary
        },
        [DealStatus.ACTIVE]: {
            backgroundColor: theme.palette.info.main,
            textColor: theme.palette.text.primaryInverse
        }
    };
    return dealStatusProperties[status];
}

export default getDealStatusProperties;